import { z } from 'zod';

export const projectSchema = z.object({
  id: z.string(),
  number: z.string().optional().nullable(),
  name: z.string(),
  stage: z.string().nullish(),
  organizationId: z.string(),
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().nullish(),

  country: z.string().nullish(),
  createdAt: z.string().datetime().default(new Date().toISOString()),
  updatedAt: z.string().datetime().default(new Date().toISOString()),
  updatedSettingsAt: z.string().datetime().nullish(),
  updatedInfoAt: z.string().datetime().nullish(),
});

export type Project = z.infer<typeof projectSchema>;
