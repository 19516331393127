import { generate } from '@rocicorp/rails';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { createShapeId } from 'tldraw';
import { MarkupShape, markupShapeSchema } from './schema';

export const markupShapeOps = generate('markupShapes', markupShapeSchema.parse);

export const getVisibleShapesForIssue = async (
  tx: ReadTransaction,
  {
    issueId,
  }: {
    issueId?: string | null;
  }
) => {
  if (!issueId) return [];

  const allShapes = await markupShapeOps.list(tx);
  return allShapes
    .filter((shape) => shape.issueId === issueId)
    .filter((s) => !s.deleted);
};

export const getShapesForPageAndIssue = async (
  tx: ReadTransaction,
  {
    issueId,
    pageId,
  }: {
    issueId?: string;
    pageId?: string;
  }
) => {
  if (!issueId || !pageId) return [];

  const allShapes = await markupShapeOps.list(tx);
  return allShapes
    .filter((shape) => shape.issueId === issueId)
    .filter((s) => s.drawingSetPageId === pageId);
};

export const getShapesForPageAndIssues = async (
  tx: ReadTransaction,
  {
    issueIds,
    pageId,
  }: {
    issueIds: string[];
    pageId: string;
  }
) => {
  const allShapes = await markupShapeOps.list(tx);
  return allShapes
    .filter((s) => issueIds.includes(s.issueId))
    .filter((s) => s.drawingSetPageId === pageId);
};

export async function putMarkupShape(
  tx: WriteTransaction,
  args: {
    id: string;
    tldrawId: string;
    drawingSetPageId: string;
    issueId: string;
    props: string;
    rotation: number;
    x: number;
    y: number;
    type: string;
    deleted: boolean;
    lastModifiedOnCanvasId?: string;
    createdAt?: string;
    updatedAt: string;
  }
) {
  const existing = await markupShapeOps.get(tx, args.id);
  if (existing) {
    await markupShapeOps.set(tx, { ...args, createdAt: existing.createdAt });
  } else {
    await markupShapeOps.set(tx, { ...args, createdAt: args.updatedAt });
  }
}

export module MarkupShapes {
  export const findByTldrawId = async (
    tx: ReadTransaction,
    tldrawId: string
  ) => {
    const allShapes = await markupShapeOps.list(tx);
    return allShapes.find((s) => s.tldrawId === tldrawId);
  };

  export const tldrawShapeId = ({ tldrawId }: MarkupShape) => {
    const withoutPrefix = tldrawId.split(':')[1];
    return createShapeId(withoutPrefix);
  };
}
