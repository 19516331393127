import { z } from 'zod';

export const notificationSchema = z.object({
  id: z.string(),
  type: z.string(),
  url: z.string().nullish(),
  message: z.string(),
  organizationName: z.string().nullish(),
  projectName: z.string().nullish(),
  topicName: z.string().nullish(),
  avatarFirstName: z.string().nullish(),
  avatarLastName: z.string().nullish(),
  avatarId: z.string().nullish(),
  readAt: z.string().datetime().nullish(),
  seenAt: z.string().datetime().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type Notification = z.infer<typeof notificationSchema>;
