import { z } from 'zod';

export type Question = z.infer<typeof questionSchema>;

export const questionSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  content: z.string(),
  answer: z.string().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  completedAt: z.string().datetime().nullish(),
});
