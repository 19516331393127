import { z } from 'zod';

export type Company = z.infer<typeof companySchema>;

export const companySchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  name: z.string(),
  url: z.string().optional(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
