import { DrawingArea } from '@/models/drawing-area';
import { WriteTransaction } from 'replicache';

export default async function createDrawingAreas(
  tx: WriteTransaction,
  args: {
    drawingAreas: {
      id: string;
      organizationId: string;
      projectId: string;
      name: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
) {
  for (const drawingArea of args.drawingAreas) {
    await DrawingArea.set(tx, drawingArea);
  }
}
