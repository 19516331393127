import { ReadonlyJSONObject, WriteTransaction } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Company } from './company/model';
import { ObjectPool } from './object-pool';
import { Project } from './project';

export class ProjectsCompaniesGrant extends ApplicationRecord {
  projectId: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'projectsCompaniesGrants' as const;

  static schema = z.object({
    id: z.string(),
    projectId: z.string(),
    companyId: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  static mutations = {
    async create(
      tx: WriteTransaction,
      args: z.infer<typeof ProjectsCompaniesGrant.schema>
    ) {
      await ProjectsCompaniesGrant.set(tx, args);
    },

    async delete(tx: WriteTransaction, args: { id: string }) {
      await ProjectsCompaniesGrant.delete(tx, args.id);
    },
  };

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.projectId = this.attribute('projectId');
    this.companyId = this.attribute('companyId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get company() {
    return this.belongsTo(Company, this.companyId);
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }
}
