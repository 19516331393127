import { RfiQuestionAttachment } from '@/models/rfi-question-attachment';
import { WriteTransaction } from 'replicache';

export default async function createRfiQuestionAttachment(
  tx: WriteTransaction,
  args: {
    id: string;
    createdAt: string;
    rfiId?: string | null | undefined;
  }
) {
  await RfiQuestionAttachment.set(tx, {
    id: args.id,
    createdAt: args.createdAt,
    rfiId: args.rfiId,
  });
}
