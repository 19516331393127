import { revisionsBySheetNumber } from '@/lib/revision-queries';
import { generate, ReadTransaction, WriteTransaction } from '@rocicorp/rails';
import { uniq } from 'lodash';
import drawingSetOps, { getDrawingSetsForArea } from '../drawing-set/ops';
import { DrawingSet } from '../drawing-set/schema';
import { DrawingSetPage, drawingSetPageSchema } from './schema';

export const drawingSetPageOps = generate(
  'drawingSetPages',
  drawingSetPageSchema.parse
);

export type PublishDrawingSetPagesArgs = {
  drawingSetPageIds: Array<string>;
};

export async function allVisiblePages(tx: ReadTransaction) {
  return revisionsBySheetNumber(
    await drawingSetOps.list(tx),
    (await drawingSetPageOps.list(tx))
      .filter((x) => !x.deletedAt)
      .filter((x) => x.state === 'published')
  ).map((x) => x.revisions[0]);
}

export async function publishDrawingSetPages(
  tx: WriteTransaction,
  args: PublishDrawingSetPagesArgs
) {
  const idSet = new Set(args.drawingSetPageIds);
  for (const id of idSet) {
    await drawingSetPageOps.update(tx, { id, state: 'published' });
  }
}

export async function unpublishDrawingSetPages(
  tx: WriteTransaction,
  args: PublishDrawingSetPagesArgs
) {
  const idSet = new Set(args.drawingSetPageIds);
  for (const id of idSet) {
    await drawingSetPageOps.update(tx, { id, state: 'awaiting_publish' });
  }
}

export async function getDrawingAreaPages(
  tx: ReadTransaction,
  drawingAreaId: string
) {
  const drawingSets = await getDrawingSetsForArea(tx, drawingAreaId);
  const pages = await drawingSetPageOps.list(tx);

  return pages.reduce(
    (acc, page) => {
      const drawingSet = drawingSets.find(
        (set) => set.id === page.drawingSetId
      );
      if (drawingSet) {
        acc.push({ ...page, drawingSet });
      }

      return acc;
    },
    [] as Array<DrawingSetPage & { drawingSet: DrawingSet }>
  );
}

export async function getDrawingSetPages(
  tx: ReadTransaction,
  drawingSetId: string
) {
  let pages = await drawingSetPageOps.list(tx);
  return pages.filter((page) => page.drawingSetId === drawingSetId);
}

export async function getDrawingSetPagesByIds(
  tx: ReadTransaction,
  args: { ids: string[] }
) {
  const ids = uniq(args.ids);
  return (await drawingSetPageOps.list(tx)).filter((p) => ids.includes(p.id));
}

type ConfirmDrawingSetPageArgs = {
  id: string;
  drawingDisciplineId: string;
  sheetNumber: string;
  sheetDate: string | null;
  title: string;
  updatedAt: string;
};

export async function confirmDrawingSetPage(
  tx: WriteTransaction,
  args: ConfirmDrawingSetPageArgs
) {
  await drawingSetPageOps.update(tx, { ...args, state: 'awaiting_publish' });
}

export type UpdateDrawingSetPageArgs = {
  id: string;
  sheetNumber: string | null;
  title: string | null;
  sheetDate: string | null;
  disciplineId: string | null;
  updatedAt: string;
};

export async function updateDrawingSetPage(
  tx: WriteTransaction,
  args: UpdateDrawingSetPageArgs
) {
  await drawingSetPageOps.update(tx, args);
}

export async function deleteDrawingSetPage(
  tx: WriteTransaction,
  args: {
    id: string;
    deletedAt: string;
  }
) {
  await drawingSetPageOps.update(tx, args);
}

export type BulkEditDrawingSetPageArgs = {
  ids: string[];
  sheetDate: string | null;
  updatedAt: string;
};

export async function bulkEditDrawingSetPage(
  tx: WriteTransaction,
  args: BulkEditDrawingSetPageArgs
) {
  for (const id of args.ids) {
    await drawingSetPageOps.update(tx, { id, ...args });
  }
}

export async function setDrawingSetPageMeasure(
  tx: WriteTransaction,
  args: {
    id: string;
    updatedAt: string;
    pageMeasure: number | null | undefined;
    realMeasure: number | null | undefined;
    measureConversion: string | null | undefined;
    measureShape: string | null | undefined;
    roundToNearest: string | null | undefined;
  }
) {
  await drawingSetPageOps.update(tx, args);
}
