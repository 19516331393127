import { generate, WriteTransaction } from '@rocicorp/rails';
import { compact, sortBy } from 'lodash';
import { ReadTransaction } from 'replicache';
import { ProjectsCompaniesGrant } from '../projects-companies-grant';
import { companySchema } from './schema';

export const companyOps = generate('companies', companySchema.parse);

export async function getCompaniesForProject(
  tx: ReadTransaction,
  projectId: string
) {
  const companies = await companyOps.list(tx);
  const projectsCompaniesGrants = await ProjectsCompaniesGrant.list(tx);

  return sortBy(
    compact(
      projectsCompaniesGrants
        .filter((x) => x.projectId === projectId)
        .map((m) => companies.find((c) => c.id === m.companyId))
    ),
    (x) => x.name.toLowerCase()
  );
}

export async function createCompany(
  tx: WriteTransaction,
  args: {
    id: string;
    organizationId: string;
    name: string;
    url?: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await companyOps.set(tx, args);
}
