import { Photo } from '@/models/photo';
import { WriteTransaction } from 'replicache';

export default async function createUnattachedPhoto(
  tx: WriteTransaction,
  args: {
    id: string;
    uploaderId: string;
    createdAt: string;
  }
) {
  await Photo.set(tx, args);
}
