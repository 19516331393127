import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { PendingAttachment } from './pending-attachment/model';

export class RfiResponseAttachment extends ApplicationRecord {
  responseId: string;
  fileName?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: string;

  static prefix = 'rfiResponseAttachments' as const;

  static schema = z.object({
    id: z.string(),
    responseId: z.string(),
    fileName: z.string().nullish(),
    fileUrl: z.string().nullish(),
    thumbnailUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.responseId = this.attribute('responseId');
    this.fileName = this.attribute('fileName');
    this.fileUrl = this.attribute('fileUrl');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.createdAt = this.attribute('createdAt');
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
