import { z } from 'zod';

export type OrganizationMembership = z.infer<
  typeof organizationMembershipSchema
>;

export const organizationMembershipSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  userId: z.string(),
  role: z.enum(['organization_manager']),
});
