import { Submittal } from '@/models/submittal';
import { WriteTransaction } from 'replicache';

export default async function distributeSubmittal(
  tx: WriteTransaction,
  args: {
    submittalId: string;
  }
) {
  const submittal = await Submittal.mustGet(tx, args.submittalId);

  await Submittal.set(tx, {
    ...submittal,
    status: 'done',
  });
}
