import { Rfi } from '@/models/rfi';
import { RfiQuestionAttachment } from '@/models/rfi-question-attachment';
import { WriteTransaction } from 'replicache';

export default async function updateRfiDescription(
  tx: WriteTransaction,
  args: {
    id: string;
    now: string;
    question: string;
    attachmentIds: string[];
  }
) {
  const rfi = await Rfi.get(tx, args.id);
  const existingQuestionAttachments = (
    await RfiQuestionAttachment.list(tx)
  ).filter((x) => x.rfiId === args.id);

  if (!rfi) return;

  await Rfi.set(tx, {
    ...rfi,
    question: args.question,
    updatedAt: args.now,
  });

  const questionAttachmentsToDelete = existingQuestionAttachments.filter(
    (x) => !args.attachmentIds.includes(x.id)
  );
  for (const questionAttachment of questionAttachmentsToDelete) {
    await RfiQuestionAttachment.delete(tx, questionAttachment.id);
  }

  const questionAttachmentsToCreate = args.attachmentIds.filter(
    (id) => !existingQuestionAttachments.map((x) => x.id).includes(id)
  );
  for (const questionAttachmentId of questionAttachmentsToCreate) {
    await RfiQuestionAttachment.set(tx, {
      id: questionAttachmentId,
      rfiId: args.id,
      createdAt: args.now,
    });
  }
}
