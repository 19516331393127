import { Project } from '@/models/project';
import { WriteTransaction } from 'replicache';

export default async function editProjectInfo(
  tx: WriteTransaction,
  args: {
    id: string;
    stage?: string | null | undefined;
    address1?: string | null | undefined;
    address2?: string | null | undefined;
    city?: string | null | undefined;
    state?: string | null | undefined;
    postalCode?: string | null | undefined;
    country?: string | null | undefined;
    infoUpdatedAt: string;
  }
) {
  const project = await Project.mustGet(tx, args.id);
  await Project.set(tx, {
    ...project,
    stage: args.stage,
    address1: args.address1,
    address2: args.address2,
    city: args.city,
    state: args.state,
    postalCode: args.postalCode,
    country: args.country,
    infoUpdatedAt: args.infoUpdatedAt,
  });
}
