import { generate } from '@rocicorp/rails';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { PendingAttachment, pendingAttachmentSchema } from './schema';

export const pendingAttachmentOps = generate(
  'pendingAttachments',
  pendingAttachmentSchema.parse
);

export async function listPendingAttachmentsForRecord(
  tx: ReadTransaction,
  recordType: string,
  recordId: string
) {
  const pendingAttachments = await pendingAttachmentOps.list(tx);
  return pendingAttachments.filter(
    (pendingAttachment) =>
      pendingAttachment.recordType === recordType &&
      pendingAttachment.recordId === recordId
  );
}

type CreatePendingAttachmentArgs = PendingAttachment;

export async function createPendingAttachment(
  tx: WriteTransaction,
  args: CreatePendingAttachmentArgs
) {
  await pendingAttachmentOps.set(tx, args);
}

type StartUploadingPendingAttachmentArgs = {
  id: string;
  updatedAt: string;
};

export async function startUploadingPendingAttachment(
  tx: WriteTransaction,
  { id, updatedAt }: StartUploadingPendingAttachmentArgs
) {
  const attachment = await pendingAttachmentOps.mustGet(tx, id);
  await pendingAttachmentOps.set(tx, {
    ...attachment,
    state: 'uploading',
    updatedAt,
  });
}

type UploadedPendingAttachmentArgs = {
  id: string;
  signedId: string;
};

// This mutation differs from the server implementation so that we can still show
// the pending mutation before we receive the next pull from the server.
export async function uploadedPendingAttachment(
  tx: WriteTransaction,
  { id }: UploadedPendingAttachmentArgs
) {
  const attachment = await pendingAttachmentOps.get(tx, id);
  if (!attachment) {
    console.warn('uploadedPendingAttachment: attachment not found', { id });
    return;
  }

  await pendingAttachmentOps.set(tx, { ...attachment, state: 'uploaded' });
}

type FailedPendingAttachmentArgs = {
  id: string;
  updatedAt: string;
};

export async function failedPendingAttachment(
  tx: WriteTransaction,
  { id, updatedAt }: FailedPendingAttachmentArgs
) {
  const attachment = await pendingAttachmentOps.mustGet(tx, id);
  await pendingAttachmentOps.set(tx, {
    ...attachment,
    state: 'initialized',
    updatedAt,
  });
}
