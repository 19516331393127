import { z } from 'zod';

export type Rfi = z.infer<typeof rfiSchema>;

export type RfiStatus = 'open' | 'closed' | 'cancelled';

export type RfiBallInCourtStatus = 'manager' | 'respondent';

export const rfiSchema = z.object({
  id: z.string(),
  ballInCourtStatus: z.enum(['manager', 'respondent']),
  dueOn: z.string().date().nullish(),
  openedAt: z.string().datetime(),
  closedAt: z.string().datetime().nullish(),
  question: z.string(),
  status: z.enum(['open', 'closed', 'cancelled']),
  subject: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  managerUserId: z.string(),

  // these are set by the server and will need dummy values
  number: z.number().nonnegative(),
  topicId: z.string().nullish(),
});

export const rfiSubscriberSchema = z.object({
  id: z.string(),
  responseRequired: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  rfiId: z.string(),
  userId: z.string(),
});

export const rfiResponseSchema = z.object({
  id: z.string(),
  content: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  rfiId: z.string(),
  subscriberId: z.string(),
  acceptedAt: z.string().nullish(),
});

export const rfiQuestionAttachmentSchema = z.object({
  id: z.string(),
  rfiId: z.string(),
  fileName: z.string().nullish(),
  fileUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
  createdAt: z.string().datetime(),
});

export type RfiQuestionAttachment = z.infer<typeof rfiQuestionAttachmentSchema>;

export const rfiResponseAttachmentSchema = z.object({
  id: z.string(),
  responseId: z.string(),
  fileName: z.string().nullish(),
  fileUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
  createdAt: z.string().datetime(),
});

export type RfiResponseAttachment = z.infer<typeof rfiResponseAttachmentSchema>;
