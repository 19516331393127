import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { Topic } from './issue/model';
import { ObjectPool } from './object-pool';
import { RfiQuestionAttachment } from './rfi-question-attachment';
import { RfiResponse } from './rfi-response';
import { RfiSubscriber } from './rfi-subscriber';
import { User } from './user/model';
export const rfiStatuses = ['open', 'closed', 'cancelled'] as const;

export type RfiStatus = (typeof rfiStatuses)[number];

export const rfiBallInCourtStatuses = ['manager', 'respondent'] as const;

export type RfiBallInCourtStatus = (typeof rfiBallInCourtStatuses)[number];

export class Rfi extends ApplicationRecord {
  ballInCourtStatus: RfiBallInCourtStatus;
  dueOn?: string | null;
  openedAt: string;
  closedAt?: string | null;
  question: string;
  status: RfiStatus;
  subject: string;
  number: number;
  topicId?: string | null;
  createdAt: string;
  updatedAt: string;
  managerUserId: string;

  static prefix = 'rfis' as const;

  static schema = z.object({
    id: z.string(),
    ballInCourtStatus: z.enum(rfiBallInCourtStatuses),
    dueOn: z.string().date().nullish(),
    openedAt: z.string().datetime(),
    closedAt: z.string().datetime().nullish(),
    question: z.string(),
    status: z.enum(rfiStatuses),
    subject: z.string(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    managerUserId: z.string(),
    number: z.number().nonnegative(),
    topicId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.ballInCourtStatus = this.attribute('ballInCourtStatus');
    this.dueOn = this.attribute('dueOn');
    this.openedAt = this.attribute('openedAt');
    this.closedAt = this.attribute('closedAt');
    this.question = this.attribute('question');
    this.status = this.attribute('status');
    this.subject = this.attribute('subject');
    this.number = this.attribute('number');
    this.topicId = this.attribute('topicId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.managerUserId = this.attribute('managerUserId');
  }

  get managerUser() {
    return this.belongsTo(User, this.managerUserId);
  }

  get topic() {
    return this.belongsToOptional(Topic, this.topicId);
  }

  get subscribers() {
    return this.hasMany(RfiSubscriber, 'rfiId').filter(
      (subscriber) => !subscriber.responseRequired
    );
  }

  get respondents() {
    return this.hasMany(RfiSubscriber, 'rfiId').filter(
      (subscriber) => subscriber.responseRequired
    );
  }

  get pendingRespondents() {
    return this.respondents.filter(
      (respondent) =>
        !this.responses.some(
          (response) => response.subscriber.userId === respondent.userId
        )
    );
  }

  get responses() {
    return this.hasMany(RfiResponse, 'rfiId');
  }

  get questionAttachments() {
    return this.hasMany(RfiQuestionAttachment, 'rfiId');
  }
}
