import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { markupSessionSchema } from './schema';

export const markupSessionOps = generate(
  'markupSessions',
  markupSessionSchema.parse
);

export async function putMarkupSession(
  tx: WriteTransaction,
  args: {
    id: string;
    shapesCreated: string[];
    shapesDeleted: string[];
    shapesUpdated: string[];
    userId: string;
    issueId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await markupSessionOps.set(tx, args);
}
