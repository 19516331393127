import { ReadonlyJSONObject } from '@rocicorp/rails';
import { parseISO } from 'date-fns';
import { compact, groupBy } from 'lodash';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingSet } from './drawing-set';
import { DrawingSetPage } from './drawing-set-page';
import { ObjectPool } from './object-pool';
import { Organization } from './organization/model';
import { Project } from './project';

export class DrawingArea extends ApplicationRecord {
  id: string;
  projectId: string;
  organizationId: string;
  createdAt: string;
  updatedAt: string;
  name?: string | null | undefined;
  currentDrawingsPdfUrl?: string | null | undefined;

  static prefix = 'drawingAreas' as const;

  static schema = z.object({
    id: z.string(),
    name: z.string().nullish(),
    projectId: z.string(),
    organizationId: z.string(),
    currentDrawingsPdfUrl: z.string().nullish(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.projectId = this.attribute('projectId');
    this.organizationId = this.attribute('organizationId');
    this.currentDrawingsPdfUrl = this.attribute('currentDrawingsPdfUrl');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get project() {
    return this.belongsTo(Project, this.projectId);
  }

  get organization() {
    return this.belongsTo(Organization, this.organizationId);
  }

  get drawingSets() {
    return this.hasMany(DrawingSet, 'drawingAreaId');
  }

  get drawingSetPages() {
    return this.drawingSets.flatMap((set) => set.drawingSetPages);
  }

  get publishedDrawingSetPages() {
    return this.drawingSetPages.filter((x) => x.state === 'published');
  }

  get pageNumberRevisions() {
    const orderByRevision = (a: DrawingSetPage, b: DrawingSetPage) => {
      const setCmp =
        a.drawingSet.receivedOn &&
        b.drawingSet.receivedOn &&
        parseISO(b.drawingSet.receivedOn).getTime() -
          parseISO(a.drawingSet.receivedOn).getTime();

      if (setCmp && setCmp !== 0) return setCmp;

      return parseISO(b.createdAt).getTime() - parseISO(a.createdAt).getTime();
    };

    let groups = groupBy(this.publishedDrawingSetPages, (x) => x.sheetNumber);

    Object.keys(groups).forEach((g) => {
      groups[g] = (groups[g] || []).sort((a, b) => orderByRevision(a, b));
    });

    // {
    //   'S-101' => [newest, older, oldest, ...],
    //   ...
    // }
    return groups;
  }

  get latestRevisionDrawingSetPages() {
    return compact(
      Object.values(this.pageNumberRevisions).flatMap((v) => v[0])
    );
  }

  get whatToCallMe() {
    if (this.project.hasMultipleDrawingAreas) {
      return `${this.name} - Drawings`;
    } else {
      return 'Drawings';
    }
  }
}
