import { Submittal } from '@/models/submittal';
import { SubmittalDocument } from '@/models/submittal-document';
import { WriteTransaction } from 'replicache';

export default async function updateSubmittalDescription(
  tx: WriteTransaction,
  args: {
    id: string;
    now: string;
    description: string;
    documentIds: string[];
  }
) {
  const submittal = await Submittal.get(tx, args.id);
  const existingDocuments = (await SubmittalDocument.list(tx)).filter(
    (x) => x.submittalId === args.id
  );

  if (!submittal) return;

  await Submittal.set(tx, {
    ...submittal,
    description: args.description,
    updatedAt: args.now,
  });

  const documentsToDelete = existingDocuments.filter(
    (x) => !args.documentIds.includes(x.id)
  );
  for (const document of documentsToDelete) {
    await SubmittalDocument.delete(tx, document.id);
  }

  const documentsToCreate = args.documentIds.filter(
    (id) => !existingDocuments.map((x) => x.id).includes(id)
  );
  for (const documentId of documentsToCreate) {
    await SubmittalDocument.set(tx, {
      id: documentId,
      submittalId: args.id,
      title: '',
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}
