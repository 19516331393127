import { z } from 'zod';

export type DrawingDiscipline = z.infer<typeof drawingDisciplineSchema>;
export const drawingDisciplineSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  organizationId: z.string(),
  name: z.string().max(80),
  position: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
