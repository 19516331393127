import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { questionSchema } from './schema';

export const questionOps = generate('questions', questionSchema.parse);

export async function askQuestion(
  tx: WriteTransaction,
  args: {
    id: string;
    projectId: string;
    content: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await questionOps.set(tx, args);
}
