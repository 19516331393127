import { PunchListItem } from '@/models/punch-list-item';
import { WriteTransaction } from 'replicache';

export default async function updatePunchListItem(
  tx: WriteTransaction,
  args: {
    id: string;
    description: string | null | undefined;
    title: string;
    dueOn: string | null | undefined;
    status: 'open' | 'in_review' | 'resolved';
    approverUserId: string;
    assigneeUserId: string | null | undefined;
    updatedAt: string;
  }
) {
  const item = await PunchListItem.mustGet(tx, args.id);

  await PunchListItem.set(tx, {
    id: args.id,
    description: args.description,
    title: args.title,
    status: args.status,
    topicId: item.topicId,
    creatorUserId: item.creatorUserId,
    approverUserId: args.approverUserId,
    assigneeUserId: args.assigneeUserId,
    createdAt: item.createdAt,
    updatedAt: args.updatedAt,
  });
}
