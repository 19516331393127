import { z } from 'zod';

export type Discussion = z.infer<typeof discussionSchema>;

export const discussionSchema = z.object({
  id: z.string(),
  discussableType: z.string(),
  discussableId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
