import { generate } from '@rocicorp/rails';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { z } from 'zod';
import { discussionSchema } from './schema';

export const discussionOps = generate('discussions', discussionSchema.parse);

export async function discussableMustGet(
  tx: ReadTransaction,
  discussableType: string,
  discussableId: string
) {
  const discussions = await discussionOps.list(tx);
  const discussion = discussions.find(
    (d) =>
      d.discussableType === discussableType && d.discussableId === discussableId
  );
  if (!discussion) {
    throw new Error('Discussion not found');
  }
  return discussion;
}

export async function discussableGet(
  tx: ReadTransaction,
  discussableType: string,
  discussableId: string
) {
  const discussions = await discussionOps.list(tx);
  const discussion = discussions.find(
    (d) =>
      d.discussableType === discussableType && d.discussableId === discussableId
  );
  return discussion;
}

type CreateDiscussionArgs = z.infer<typeof discussionSchema>;

export async function createDiscussion(
  tx: WriteTransaction,
  args: CreateDiscussionArgs
) {
  const existing = await discussableGet(
    tx,
    args.discussableType,
    args.discussableId
  );

  if (existing) {
    return;
  }

  await discussionOps.set(tx, args);
}
