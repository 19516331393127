import { z } from 'zod';

export type User = z.infer<typeof userSchema>;

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  otpEnabled: z.boolean(),
  verified: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  avatarUrl: z.string().nullish(),
  phoneNumber: z.string().nullish(),
});
