import { z } from 'zod';

export type CommentThread = z.infer<typeof commentThreadSchema>;

export const commentThreadSchema = z.object({
  id: z.string(),
  discussionId: z.string(),
  resolvedAt: z.string().datetime().nullish(),
  resolvedById: z.string().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
