import { z } from 'zod';

export type MarkupSession = z.infer<typeof markupSessionSchema>;

export const markupSessionSchema = z.object({
  id: z.string(),
  shapesCreated: z.array(z.string()),
  shapesDeleted: z.array(z.string()),
  shapesUpdated: z.array(z.string()),
  userId: z.string(),
  issueId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
