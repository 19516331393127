import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { DrawingSetPage } from '../drawing-set-page';
import { ObjectPool } from '../object-pool';

export class MarkupShape extends ApplicationRecord {
  tldrawId: string;
  drawingSetPageId: string;
  issueId: string;
  props: string;
  rotation: number;
  x: number;
  y: number;
  type: string;
  deleted: boolean;
  lastModifiedOnCanvasId?: string | null;
  createdAt: string;
  updatedAt: string;

  static prefix = 'markupShapes' as const;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.tldrawId = this.attribute('tldrawId', z.string());
    this.drawingSetPageId = this.attribute('drawingSetPageId', z.string());
    this.issueId = this.attribute('issueId', z.string());
    this.props = this.attribute('props', z.string());
    this.rotation = this.attribute('rotation', z.number());
    this.x = this.attribute('x', z.number());
    this.y = this.attribute('y', z.number());
    this.type = this.attribute('type', z.string());
    this.deleted = this.attribute('deleted', z.boolean());
    this.lastModifiedOnCanvasId = this.attribute(
      'lastModifiedOnCanvasId',
      z.string().nullish()
    );
    this.createdAt = this.attribute(
      'createdAt',
      z.string().datetime({ offset: true })
    );
    this.updatedAt = this.attribute(
      'updatedAt',
      z.string().datetime({ offset: true })
    );
  }

  get drawingSetPage() {
    return this.belongsTo(DrawingSetPage, this.drawingSetPageId);
  }

  get drawingSet() {
    return this.drawingSetPage.drawingSet;
  }

  get punchListItemId() {
    return JSON.parse(this.props)['punchListItemId'];
  }
}
