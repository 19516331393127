import { z } from 'zod';

export type DailyLog = z.infer<typeof dailyLogSchema>;
export const dailyLogSchema = z.object({
  id: z.string(),
  date: z.string().date(),
  notes: z.string(),
  weatherNotes: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type DailyLogPersonnelEntry = z.infer<
  typeof dailyLogPersonnelEntrySchema
>;
export const dailyLogPersonnelEntrySchema = z.object({
  id: z.string(),
  dailyLogId: z.string(),
  workerCount: z.number(),
  hoursWorked: z.coerce.number(),
  companyId: z.string(),
  notes: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type DailyLogEquipmentEntry = z.infer<
  typeof dailyLogEquipmentEntrySchema
>;
export const dailyLogEquipmentEntrySchema = z.object({
  id: z.string(),
  dailyLogId: z.string(),
  name: z.string(),
  inspectedAt: z.string().nullish(),
  notes: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});
