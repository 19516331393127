import { Rfi } from '@/models/rfi';
import { WriteTransaction } from 'replicache';

export default async function renameRfi(
  tx: WriteTransaction,
  args: {
    id: string;
    subject: string;
    now: string;
  }
) {
  const rfi = await Rfi.get(tx, args.id);
  if (!rfi) return;

  await Rfi.set(tx, { ...rfi, subject: args.subject, updatedAt: args.now });
}
