import { z } from 'zod';

export type Issue = z.infer<typeof issueSchema>;

export const issueSchema = z.object({
  id: z.string(),
  title: z.string(),
  status: z.enum(['new', 'draft', 'editing', 'converted', 'archived']),
  type: z.enum(['topic', 'punch_list', 'rfi', 'submittal']),
  companyAccessible: z.boolean(),
  organizationAccessible: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
