import { z } from 'zod';

export type DrawingDisciplineAbbreviation = z.infer<
  typeof drawingDisciplineAbbreviationSchema
>;

export const drawingDisciplineAbbreviationSchema = z.object({
  id: z.string(),
  drawingDisciplineId: z.string(),
  projectId: z.string(),
  organizationId: z.string(),
  name: z.string().min(1).max(10),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
