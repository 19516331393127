import { generate, WriteTransaction } from '@rocicorp/rails';
import { ReadTransaction } from 'replicache';
import { issueMembershipSchema } from './schema';

export const issueMembershipOps = generate(
  'issueMemberships',
  issueMembershipSchema.parse
);

export async function createIssueMembership(
  tx: WriteTransaction,
  args: {
    id: string;
    userId: string;
    issueId: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await issueMembershipOps.set(tx, args);
}

export async function removeIssueMembership(
  tx: WriteTransaction,
  args: { issueMembershipId: string }
) {
  await issueMembershipOps.delete(tx, args.issueMembershipId);
}

export async function getIssueMemberships(tx: ReadTransaction) {
  return await issueMembershipOps.list(tx);
}
