import { Photo } from '@/models/photo';
import { PhotoAlbum } from '@/models/photo-album';
import { WriteTransaction } from 'replicache';

type CreatePhotoArgs = {
  id: string;
  uploaderId: string;
  discussionId?: string | null;
  createdAt: string;
  photoAlbumId?: string | null;
  systemManagedAlbum?: 'daily_logs' | 'camera_roll' | null | undefined;
};

export default async function createPhoto(
  tx: WriteTransaction,
  {
    id,
    uploaderId,
    discussionId,
    createdAt,
    photoAlbumId,
    systemManagedAlbum,
  }: CreatePhotoArgs
) {
  await Photo.set(tx, {
    id,
    uploaderId,
    discussionId,
    createdAt,
    photoAlbumId,
  });

  if (photoAlbumId) {
    const photoAlbum = await PhotoAlbum.get(tx, photoAlbumId);
    if (photoAlbum) {
      photoAlbum.coverPhotoId = id;
      await PhotoAlbum.set(tx, photoAlbum);
    }
  }
}
