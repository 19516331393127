import { z } from 'zod';

export type DrawingSetPage = z.infer<typeof drawingSetPageSchema>;

export const drawingSetPageSchema = z.object({
  id: z.string(),
  pageNumber: z.number().int(),
  sheetNumber: z.string().nullish(),
  title: z.string().nullish(),
  state: z.enum([
    'initialized',
    'extracting_metadata',
    'awaiting_confirmation',
    'awaiting_publish',
    'published',
  ]),
  sourceUrl: z.string().nullish(),
  sourceFileName: z.string().nullish(),
  pageUrl: z.string().nullish(),
  lowResImageUrl: z.string().nullish(),
  sheetDate: z.string().date().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  organizationId: z.string(),
  drawingSetId: z.string(),
  drawingDisciplineId: z.string().nullish(),
  width: z.number().int().nullish(),
  height: z.number().int().nullish(),
  alignmentOffsetX: z.number().nullish(),
  alignmentOffsetY: z.number().nullish(),
  deletedAt: z.string().datetime().nullish(),

  // Measure stuff, TODO: move to a separate table
  pageMeasure: z.number().nullish(),
  realMeasure: z.number().nullish(),
  measureConversion: z.string().nullish(),
  measureShape: z.string().nullish(),
  roundToNearest: z.string().nullish(),
});
