import { z } from 'zod';

export type DrawingSet = z.infer<typeof drawingSetSchema>;

export const drawingSetSchema = z.object({
  id: z.string(),
  name: z.string(),
  receivedOn: z.string().date().optional().nullable(),
  defaultSheetDate: z.string().date().optional().nullable(),
  processingState: z.enum(['initialized', 'processing', 'finished', 'error']),
  revisionNumber: z.string().max(8).optional().nullable(),
  description: z.string().max(1024).optional().nullable(),
  drawingAreaId: z.string(),
  thumbnailUrl: z.string().optional().nullable(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
