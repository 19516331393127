import { z } from 'zod';

export type DrawingArea = z.infer<typeof drawingAreaSchema>;
export const drawingAreaSchema = z.object({
  id: z.string(),
  projectId: z.string(),
  organizationId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
