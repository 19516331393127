import invariant from '@/lib/invariant';
import {
  AnnotationMapEntry,
  AnnotationsMap,
  computed,
  isObservable,
  makeObservable,
  observable,
} from 'mobx';
import {
  ReadonlyJSONObject,
  ReadTransaction,
  WriteTransaction,
} from 'replicache';
import { z } from 'zod';
import {
  ObjectPool,
  RegisteredModelPrefix,
  RegisteredModelPrototype,
} from './object-pool';

export abstract class ApplicationRecord {
  __prefix: RegisteredModelPrefix;
  id: string;
  attributes: ReadonlyJSONObject;
  objectPool: ObjectPool;

  private keysToObserve: string[] = [];
  static prefix: RegisteredModelPrefix;
  static schema: z.ZodObject<any>;

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    this.id = id;
    this.__prefix = (this.constructor as any).prefix;
    this.attributes = attributes;
    this.objectPool = objectPool;
  }

  static async get<T extends typeof ApplicationRecord>(
    this: T,
    tx: WriteTransaction | ReadTransaction,
    id: string
  ) {
    const data = await tx.get(`${this.prefix}/${id}`);
    if (!data) return null;

    return this.schema.parse(data) as z.infer<T['schema']>;
  }

  static async mustGet<T extends typeof ApplicationRecord>(
    this: T,
    tx: WriteTransaction,
    id: string
  ) {
    const data = await tx.get(`${this.prefix}/${id}`);
    invariant(data, `record ${this.prefix}/${id} not found`);

    return this.schema.parse(data) as z.infer<T['schema']>;
  }

  static async list<T extends typeof ApplicationRecord>(
    this: T,
    tx: WriteTransaction | ReadTransaction
  ) {
    const rows = tx.scan({ prefix: this.prefix + '/' });
    let parsedRows: Array<unknown> = [];
    for await (const row of rows) {
      parsedRows.push(this.schema.parse(row));
    }

    return parsedRows as z.infer<T['schema']>[];
  }

  static async set<T extends typeof ApplicationRecord>(
    this: T,
    tx: WriteTransaction,
    attrs: z.infer<T['schema']>
  ) {
    this.schema.parse(attrs);
    await tx.set(`${this.prefix}/${attrs.id}`, attrs);
  }

  static async delete<T extends typeof ApplicationRecord>(
    this: T,
    tx: WriteTransaction,
    id: string
  ) {
    await tx.del(`${this.prefix}/${id}`);
  }

  get annotations(): AnnotationsMap<this, string> {
    const result: Record<string, AnnotationMapEntry> = {};
    for (const key of this.keysToObserve) {
      result[key] = observable;
    }
    for (const key of this.getterMethods) {
      result[key] = computed;
    }
    return result;
  }

  get getterMethods(): string[] {
    const proto = Object.getPrototypeOf(this);
    return Object.getOwnPropertyNames(proto).filter(
      (key) => Object.getOwnPropertyDescriptor(proto, key)?.get
    );
  }

  observeIfNeeded() {
    if (!isObservable(this)) {
      this.observe();
    }
  }

  observe() {
    makeObservable(this, this.annotations);
  }

  protected attribute<T>(key: string, schema?: z.ZodType<T>) {
    schema ||= (this.constructor as any).schema?.shape[key];
    invariant(schema, `No schema found for ${key}`);

    const parsedValue = schema
      .describe(`${this.__prefix}.${key}`)
      .parse(this.attributes[key]);

    this.keysToObserve.push(key);

    return parsedValue;
  }

  protected belongsToOptional<T extends RegisteredModelPrototype>(
    type: T,
    foreignKeyId: string | null | undefined
  ): InstanceType<T> | null {
    if (!foreignKeyId) return null;

    return this.objectPool.find(type, foreignKeyId) as InstanceType<T> | null;
  }

  protected belongsTo<T extends RegisteredModelPrototype>(
    type: T,
    foreignKeyId: string
  ): InstanceType<T> {
    const result = this.objectPool.find(type, foreignKeyId) as InstanceType<T>;
    invariant(result, `record ${type.prefix}/${foreignKeyId} not found`);
    return result;
  }

  protected hasMany<T extends RegisteredModelPrototype>(
    type: T,
    sourceProperty: string
  ): Array<InstanceType<T>> {
    return this.objectPool
      .all(type)
      .filter((obj) => (obj as any)[sourceProperty] === this.id);
  }

  protected hasOne<T extends RegisteredModelPrototype>(
    type: T,
    sourceProperty: string
  ): InstanceType<T> | null {
    return this.hasMany(type, sourceProperty)[0] || null;
  }
}
