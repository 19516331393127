import { z } from 'zod';

export type CompanyMembership = z.infer<typeof issueMembershipSchema>;

export const issueMembershipSchema = z.object({
  id: z.string(),
  userId: z.string(),
  issueId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
