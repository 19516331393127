import { Rfi } from '@/models/rfi';
import { RfiResponse } from '@/models/rfi-response';
import { RfiSubscriber } from '@/models/rfi-subscriber';
import { WriteTransaction } from 'replicache';

export default async function updateRfiOverview(
  tx: WriteTransaction,
  args: {
    id: string;
    now: string;
    dueOn: string | null;
    managerUserId: string;
    subscribers: { id: string; userId: string }[];
    respondents: { id: string; userId: string }[];
  }
) {
  const rfi = await Rfi.get(tx, args.id);
  if (!rfi) return;

  await Rfi.set(tx, {
    ...rfi,
    dueOn: args.dueOn,
    managerUserId: args.managerUserId,
    updatedAt: args.now,
  });

  const rfiSubscribers = (await RfiSubscriber.list(tx)).filter(
    (x) => x.rfiId === args.id
  );

  const responses = (await RfiResponse.list(tx)).filter(
    (x) => x.rfiId === args.id
  );

  const subscriberUserIds = [
    ...rfiSubscribers.map((x) => x.userId),
    ...args.subscribers.map((x) => x.userId),
    ...args.respondents.map((x) => x.userId),
  ];

  for (const userId of subscriberUserIds) {
    const existing = rfiSubscribers.find((x) => x.userId === userId);
    const existingResponse = responses.find(
      (x) => x.subscriberId === existing?.id
    );
    const foundRespondent = args.respondents.find((x) => x.userId === userId);
    const foundSubscriber = args.subscribers.find((x) => x.userId === userId);

    if (existing && existingResponse) {
      continue;
    } else if (!foundRespondent && !foundSubscriber && existing) {
      await RfiSubscriber.delete(tx, existing.id);
    } else if (existing) {
      await RfiSubscriber.set(tx, {
        ...existing,
        updatedAt: args.now,
        responseRequired: foundRespondent ? true : false,
      });
    } else if (foundRespondent) {
      await RfiSubscriber.set(tx, {
        id: foundRespondent.id,
        rfiId: args.id,
        userId,
        responseRequired: true,
        createdAt: args.now,
        updatedAt: args.now,
      });
    } else if (foundSubscriber) {
      await RfiSubscriber.set(tx, {
        id: foundSubscriber.id,
        rfiId: args.id,
        userId,
        responseRequired: false,
        createdAt: args.now,
        updatedAt: args.now,
      });
    }
  }
}
