import { Photo } from '@/models/photo';
import { PhotoAlbum } from '@/models/photo-album';
import { WriteTransaction } from 'replicache';

export default async function addPhotos(
  tx: WriteTransaction,
  {
    albumId,
    albumTitleIfNew,
    photoIds,
    now,
  }: {
    albumId?: string | null | undefined;
    albumTitleIfNew: string | null | undefined;
    photoIds: string[];
    now: string;
  }
) {
  let album;
  album = await PhotoAlbum.get(tx, albumId || '');

  if (!album && albumTitleIfNew && albumId) {
    album = await PhotoAlbum.set(tx, {
      id: albumId,
      title: albumTitleIfNew,
      createdAt: now,
      updatedAt: now,
      coverPhotoId: photoIds[0],
      photosCount: photoIds.length,
    });
  }

  if (album) {
    for (const photoId of photoIds) {
      const photo = await Photo.get(tx, photoId);

      if (!photo) continue;

      await Photo.set(tx, {
        ...photo,
        photoAlbumId: album.id,
      });
    }
  }
}
