import { z } from 'zod';

export const documentSchema = z.object({
  id: z.string(),
  uploaderId: z.string(),
  path: z.string(),
  mimeType: z.string().nullish(),
  sizeBytes: z.number().nullish(),
  modifiedAt: z.string().datetime().nullish(),
  updatedAt: z.string().datetime(),
  createdAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullish(),
  fileUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
});
export type Document = z.infer<typeof documentSchema>;

export const folderSchema = z.object({
  id: z.string(),
  path: z.string(),
  organizationGrant: z.boolean(),
  projectGrant: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type Folder = z.infer<typeof folderSchema>;

export const usersDocumentsGrantSchema = z.object({
  id: z.string(),
  documentId: z.string(),
  userId: z.string(),
});
export type UsersDocumentsGrant = z.infer<typeof usersDocumentsGrantSchema>;

export const usersFoldersGrantSchema = z.object({
  id: z.string(),
  folderId: z.string(),
  userId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type UsersFoldersGrant = z.infer<typeof usersFoldersGrantSchema>;
