import { Submittal } from '@/models/submittal';
import { WriteTransaction } from 'replicache';

export default async function renameSubmittal(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    now: string;
  }
) {
  const submittal = await Submittal.get(tx, args.id);
  if (!submittal) return;

  await Submittal.set(tx, {
    ...submittal,
    title: args.title,
    updatedAt: args.now,
  });
}
