import { z } from 'zod';

export type PendingAttachment = z.infer<typeof pendingAttachmentSchema>;

export const pendingAttachmentSchema = z.object({
  id: z.string(),
  recordAttachmentName: z.string(),
  fileName: z.string(),
  state: z.string(),
  recordType: z.string(),
  recordId: z.string(),
  updatedAt: z.string().datetime(),
});
