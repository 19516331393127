import { generate, WriteTransaction } from '@rocicorp/rails';
import { companyMembershipSchema } from './schema';

export const companyMembershipOps = generate(
  'companyMemberships',
  companyMembershipSchema.parse
);

export async function createCompanyMembership(
  tx: WriteTransaction,
  args: {
    id: string;
    userId: string;
    organizationId: string;
    companyId: string;
    createdAt: string;
    updatedAt: string;
    role: 'sub_contractor';
  }
) {
  await companyMembershipOps.set(tx, args);
}

export async function removeCompanyMembership(
  tx: WriteTransaction,
  args: { companyMembershipId: string }
) {
  await companyMembershipOps.delete(tx, args.companyMembershipId);
}
