import {
  confirmDrawingSetPage,
  createDrawingDiscipline,
  publishDrawingSetPages,
  setDrawingSetPageMeasure,
  unpublishDrawingSetPages,
  updateDrawingSetPage,
} from '@/lib/drawings';
import { apiEndpoint } from '@/lib/utils';
import { logActivity } from '@/models/activity-log/ops';
import {
  addCommentToThread,
  reopenCommentThread,
  resolveCommentThread,
  startCommentThread,
} from '@/models/comment-thread/ops';
import { deleteComment, editComment } from '@/models/comment/ops';
import { createCompany } from '@/models/company/ops';
import { removeCompanyMembership } from '@/models/company_membership/ops';
import {
  deleteDailyLogEquipmentEntry,
  deleteDailyLogPersonnelEntry,
  putDailyLog,
  putDailyLogEquipmentEntry,
  putDailyLogPersonnelEntry,
} from '@/models/daily-log/ops';
import { createDiscussion } from '@/models/discussion/ops';
import {
  addFolder,
  createDocument,
  deleteDocument,
  deleteFolder,
  renameDocument,
  renameFolder,
  shareDocument,
  shareFolder,
} from '@/models/document/ops';
import {
  bulkEditDrawingSetPage,
  deleteDrawingSetPage,
} from '@/models/drawing-set-page/ops';
import { unconfirmDrawingSetPage } from '@/models/drawing-set-page/unconfirm-drawing-set-page';
import { createDrawingSet } from '@/models/drawing-set/create-drawing-set';
import { updateDrawingSet } from '@/models/drawing-set/ops';
import {
  createIssueMembership,
  removeIssueMembership,
} from '@/models/issue-membership/ops';
import {
  archiveIssue,
  createIssue,
  mergeTopics,
  moveIssueFromNewToDraft,
  newEditorCreateIssue,
  newEditorUpdateIssue,
  unarchiveIssue,
  updateIssue,
} from '@/models/issue/ops';
import { putMarkupSession } from '@/models/markup-session/ops';
import { putMarkupShape } from '@/models/markup-shape/ops';
import { markMentionAsViewed } from '@/models/mention/ops';
import { markNotificationAsRead } from '@/models/notification/ops';
import {
  createPendingAttachment,
  failedPendingAttachment,
  startUploadingPendingAttachment,
  uploadedPendingAttachment,
} from '@/models/pending-attachment/ops';
import { createProject } from '@/models/project/ops';
import { ProjectsCompaniesGrant } from '@/models/projects-companies-grant';
import { askQuestion } from '@/models/question/ops';
import {
  acceptRfiResponse,
  cancelRfi,
  createRfi,
  createRfiResponseAttachment,
  destroyRfiResponseAttachment,
  respondToRfi,
} from '@/models/rfi/ops';
import {
  createSubmittalApproverDocument,
  createSubmittalDocument,
  createSubmittalGroup,
} from '@/models/submittal/ops';
import { createUserForCompany, updateMyEmail } from '@/models/user/ops';
import addPhotos from '@/mutations/add-photos';
import changeAvatar from '@/mutations/change-avatar';
import changePunchListItemStatus from '@/mutations/change-punch-list-item-status';
import createDrawingAreas from '@/mutations/create-drawing-areas';
import createPhoto from '@/mutations/create-photo';
import createPunchListItem from '@/mutations/create-punch-list-item';
import createRfiQuestionAttachment from '@/mutations/create-rfi-question-attachment';
import { createSubmittal } from '@/mutations/create-submittal';
import createUnattachedPhoto from '@/mutations/create-unattached-photo';
import destroyRfiQuestionAttachment from '@/mutations/destroy-rfi-question-attachment';
import distributeSubmittal from '@/mutations/distribute-submittal';
import editProjectInfo from '@/mutations/edit-project-info';
import editProjectSettings from '@/mutations/edit-project-settings';
import inviteCompanyUser from '@/mutations/invite-company-user';
import renameRfi from '@/mutations/rename-rfi';
import renameSubmittal from '@/mutations/rename-submittal';
import respondToSubmittal from '@/mutations/respond-to-submittal';
import syncFeature from '@/mutations/sync-feature';
import updateDrawingAreas from '@/mutations/update-drawing-areas';
import updatePunchListItem from '@/mutations/update-punch-list-item';
import updateRfiDescription from '@/mutations/update-rfi-description';
import updateRfiOverview from '@/mutations/update-rfi-overview';
import updateSubmittalDescription from '@/mutations/update-submittal-description';
import updateSubmittalOverview from '@/mutations/update-submittal-overview';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import type { KVStoreProvider, ReadTransaction } from 'replicache';
import { Replicache } from 'replicache';

const USER_SCHEMA_VERSION = '80';
const MEMBERSHIP_SCHEMA_VERSION = '81';
const PROJECT_SCHEMA_VERSION = '84';

//
// Log Mutations
//

const logMutator = (mutatorName: string, mutator: Function) => {
  return (...args: any[]) => {
    console.log(`mutate ${mutatorName}`, args);
    return mutator(...args);
  };
};

function wrapMutators<T extends Record<string, any>>(mutators: T): T {
  if (import.meta.env.VITE_LOG_MUTATORS) {
    return Object.fromEntries(
      Object.entries(mutators).map(([name, mutator]) => [
        name,
        logMutator(name, mutator),
        mutator,
      ])
    ) as T;
  }

  return mutators;
}

//
// User Replicache
//

export const userMutators = wrapMutators({
  markNotificationAsRead,
} as const);

export const createUserReplicache = ({
  userId,
  userToken,
  kvStore,
}: {
  userId: string;
  userToken: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `user/${userId}`,
    auth: `Bearer ${userToken}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint('/replicache/pulls/user'),
    pushURL: apiEndpoint('/replicache/pushes/user'),
    schemaVersion: USER_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: userMutators,
    kvStore,
  });

  return replicache;
};

export type UserReplicache = ReturnType<typeof createUserReplicache>;

export const UserCacheContext = React.createContext<ReturnType<
  typeof createUserReplicache
> | null>(null);

export const useUserCache = () => {
  const cache = useContext(UserCacheContext);
  if (!cache) throw new Error('User replicache not configured');

  return cache;
};

//
// Membership Replicache
//

export const membershipMutators = wrapMutators({
  createCompany,
  createProject,
  editProjectInfo,
  editProjectSettings,
  createProjectsCompaniesGrant: ProjectsCompaniesGrant.mutations.create,
  createUserForCompany,
  markMentionAsViewed,
  removeCompanyMembership,
  deleteProjectsCompaniesGrant: ProjectsCompaniesGrant.mutations.delete,
  updateMyEmail,
  changeAvatar,
  inviteCompanyUser,
} as const);

export const createMembershipReplicache = ({
  userId,
  organizationId,
  token,
  kvStore,
}: {
  userId: string;
  organizationId: string;
  token: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `organizationMembership/${userId}/${organizationId}`, // should be under 'membership/*' but no harm
    auth: `Bearer ${token}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint('/replicache/pulls/membership'),
    pushURL: apiEndpoint('/replicache/pushes/membership'),
    schemaVersion: MEMBERSHIP_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: membershipMutators,
    kvStore,
  });

  return replicache;
};

export type MembershipReplicache = ReturnType<
  typeof createMembershipReplicache
>;

export const MembershipCacheContext = React.createContext<ReturnType<
  typeof createMembershipReplicache
> | null>(null);

export const useMembershipCache = () => {
  const cache = useContext(MembershipCacheContext);
  if (!cache) throw new Error('Membership replicache not configured');

  return cache;
};

//
// Project Replicache
//

export const projectMutators = wrapMutators({
  acceptRfiResponse,
  addCommentToThread,
  addFolder,
  archiveIssue,
  askQuestion,
  bulkEditDrawingSetPage,
  cancelRfi,
  changePunchListItemStatus,
  confirmDrawingSetPage,
  createDiscussion,
  createDocument,
  createDrawingAreas,
  createDrawingDiscipline,
  createDrawingSet,
  createIssue,
  createIssueMembership,
  createPendingAttachment,
  createPhoto,
  createPunchListItem,
  createRfi,
  createRfiQuestionAttachment,
  createRfiResponseAttachment,
  createSubmittal,
  createSubmittalApproverDocument,
  createSubmittalDocument,
  createSubmittalGroup,
  createUnattachedPhoto,
  deleteComment,
  deleteDailyLogEquipmentEntry,
  deleteDailyLogPersonnelEntry,
  deleteDocument,
  deleteDrawingSetPage,
  deleteFolder,
  destroyRfiQuestionAttachment,
  destroyRfiResponseAttachment,
  distributeSubmittal,
  editComment,
  failedPendingAttachment,
  logActivity,
  mergeTopics,
  moveIssueFromNewToDraft,
  newEditorCreateIssue,
  newEditorUpdateIssue,
  publishDrawingSetPages,
  putDailyLog,
  putDailyLogEquipmentEntry,
  putDailyLogPersonnelEntry,
  putMarkupSession,
  putMarkupShape,
  removeIssueMembership,
  renameDocument,
  renameFolder,
  renameRfi,
  renameSubmittal,
  reopenCommentThread,
  resolveCommentThread,
  respondToRfi,
  respondToSubmittal,
  setDrawingSetPageMeasure,
  shareDocument,
  shareFolder,
  startCommentThread,
  startUploadingPendingAttachment,
  unarchiveIssue,
  unconfirmDrawingSetPage,
  unpublishDrawingSetPages,
  updateDrawingAreas,
  updateDrawingSet,
  updateDrawingSetPage,
  updateIssue,
  updatePunchListItem,
  updateRfiDescription,
  updateRfiOverview,
  updateSubmittalDescription,
  updateSubmittalOverview,
  uploadedPendingAttachment,
  syncFeature,
  addPhotos,
} as const);

export const createProjectReplicache = ({
  userId,
  organizationId,
  projectId,
  token,
  kvStore,
}: {
  userId: string;
  organizationId: string;
  projectId: string;
  token: string;
  kvStore: KVStoreProvider | 'mem' | 'idb';
}) => {
  const replicache = new Replicache({
    name: `project/${userId}/${organizationId}/${projectId}`,
    auth: `Bearer ${token}`,
    licenseKey: import.meta.env.VITE_REPLICACHE_LICENSE_KEY,
    pullURL: apiEndpoint(`/replicache/pulls/project/${projectId}`),
    pushURL: apiEndpoint(`/replicache/pushes/project/${projectId}`),
    schemaVersion: PROJECT_SCHEMA_VERSION,
    pullInterval: 60000,
    mutators: projectMutators,
    kvStore,
  });

  return replicache;
};

export type ProjectReplicache = ReturnType<typeof createProjectReplicache>;

export const ProjectCacheContext = React.createContext<ReturnType<
  typeof createProjectReplicache
> | null>(null);

export const useProjectCache = () => {
  const cache = useContext(ProjectCacheContext);
  if (!cache) throw new Error('Project replicache not configured');

  return cache;
};

//
// Other Stuff
//

// We wrap all the callbacks in a `unstable_batchedUpdates` call to ensure that
// we do not render things more than once over all of the changed subscriptions.

let hasPendingCallback = false;
let callbacks: (() => void)[] = [];

function doCallback() {
  const cbs = callbacks;
  callbacks = [];
  hasPendingCallback = false;
  unstable_batchedUpdates(() => {
    for (const callback of cbs) {
      callback();
    }
  });
}

function wrapPromise<T>(promise: Promise<T>) {
  let status = 'pending';
  let result: any;
  let suspender = promise.then(
    (r: T) => {
      status = 'success';
      result = r;
    },
    (e: any) => {
      status = 'error';
      result = e;
    }
  );
  return {
    read() {
      if (status === 'pending') {
        throw suspender;
      } else if (status === 'error') {
        throw result;
      } else if (status === 'success') {
        return result;
      }
    },
  };
}

const cache = new Map<string, any>();

function getSuspender<T>(
  rep: Replicache,
  query: (tx: ReadTransaction) => Promise<T>,
  keys: Array<any>
) {
  const key = JSON.stringify(keys);
  const existing = cache.get(key);
  if (existing) return existing;
  const suspender = wrapPromise(rep.query(query));
  cache.set(key, suspender);
  return suspender;
}

/**
 * React Suspense integration for Replicache.
 * Taken and modified from https://gist.github.com/dpeek/030270187e49de7116cad3ccf2ea030f.
 * Hopefully built into Replicache some day - see https://github.com/rocicorp/replicache-react/issues/19.
 *
 * @param rep Replicache instance
 * @param query Query function to run.
 * The caller does not need to memoize this function -
 * useSuspendSubscribe memoizes it based on the combined set of keys and deps.
 * @param keys A set of keys which, along with `deps`, uniquely identifies the query.
 * @param deps useCallback dependences for `query`.
 */
export function useSuspendSubscribe<R>(
  rep: Replicache,
  query: (tx: ReadTransaction) => Promise<R>,
  keys: Array<any>,
  deps: Array<any>
): R {
  const fullKeys = [...keys, ...deps];

  const cachedQuery = useCallback(query, fullKeys);
  const [snapshot, setSnapshot] = useState(() =>
    getSuspender(rep, cachedQuery, fullKeys).read()
  );

  useEffect(() => {
    return rep.subscribe(cachedQuery, {
      onData: (data: any) => {
        if (data === snapshot) {
          return;
        }
        const updatedSuspender = wrapPromise(Promise.resolve(data));
        cache.set(JSON.stringify(fullKeys), updatedSuspender);
        callbacks.push(() => setSnapshot(data));
        if (!hasPendingCallback) {
          void Promise.resolve().then(doCallback);
          hasPendingCallback = true;
        }
      },
    });
  }, [rep, cachedQuery]);

  return snapshot;
}
