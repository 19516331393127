import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { PendingAttachment } from './pending-attachment/model';
import { Rfi } from './rfi';

export class RfiQuestionAttachment extends ApplicationRecord {
  rfiId: string | null;
  fileName?: string | null;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
  createdAt: string;

  static prefix = 'rfiQuestionAttachments' as const;

  static schema = z.object({
    id: z.string(),
    rfiId: z.string().nullish(),
    fileName: z.string().nullish(),
    fileUrl: z.string().nullish(),
    thumbnailUrl: z.string().nullish(),
    createdAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.rfiId = this.attribute('rfiId');
    this.fileName = this.attribute('fileName');
    this.fileUrl = this.attribute('fileUrl');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.createdAt = this.attribute('createdAt');
  }

  get rfi() {
    return this.belongsToOptional(Rfi, this.rfiId);
  }

  get pendingAttachment() {
    return this.hasOne(PendingAttachment, 'recordId');
  }
}
