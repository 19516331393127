import {
  DrawingArea,
  drawingAreaOps,
  DrawingSet,
  DrawingSetPage,
} from '@/lib/drawings';
import { commentThreadOps } from '@/models/comment-thread/ops';
import { commentOps } from '@/models/comment/ops';
import { discussionOps } from '@/models/discussion/ops';
import { drawingSetPageOps } from '@/models/drawing-set-page/ops';
import drawingSetOps from '@/models/drawing-set/ops';
import { issueOps } from '@/models/issue/ops';
import { markupShapeOps } from '@/models/markup-shape/ops';
import { generate } from '@rocicorp/rails';
import { sortBy } from 'lodash';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { CommentThread } from '../comment-thread/schema';
import { Comment } from '../comment/schema';
import { Discussion } from '../discussion/schema';
import { Issue } from '../issue/schema';
import { markupSessionOps } from '../markup-session/ops';
import { MarkupSession } from '../markup-session/schema';
import { MarkupShape } from '../markup-shape/schema';
import { PunchListItem, PunchListItemStatus } from '../punch-list-item';
import { User } from '../user/schema';
import {
  ActivityLog,
  activityLogSchema,
  AddComment,
  AddIssue,
  AddThread,
  AddUserToIssue,
  Generic,
  LoadedActivityLog,
  LoadedAddComment,
  LoadedAddIssue,
  LoadedAddThread,
  LoadedAddUserToIssue,
  LoadedGeneric,
  LoadedMarkupSessionLog,
  LoadedPunchListItemCreate,
  LoadedPunchListItemInReview,
  LoadedPunchListItemOpen,
  LoadedPunchListItemResolved,
  LoadedRemoveUserFromIssue,
  LoadedReopenThread,
  LoadedResolveThread,
  MarkupSessionLog,
  PunchListItemCreate,
  PunchListItemInReview,
  PunchListItemOpen,
  PunchListItemResolved,
  RemoveUserFromIssue,
  ReopenThread,
  ResolveThread,
} from './schema';

export const activityLogOps = generate('activityLogs', activityLogSchema.parse);

export async function logActivity(tx: WriteTransaction, args: ActivityLog) {
  await activityLogOps.set(tx, args);
}

type LoaderContext = {
  comments: Comment[];
  issues: Issue[];
  commentThreads: CommentThread[];
  discussions: Discussion[];
  markupShapes: MarkupShape[];
  drawingSetPages: DrawingSetPage[];
  drawingSets: DrawingSet[];
  drawingAreas: DrawingArea[];
  users: User[];
  markupSessions: MarkupSession[];
  punchListItems: {
    id: string;
    title: string;
    status: PunchListItemStatus;
    topicId: string;
    creatorUserId: string;
    approverUserId: string;
    createdAt: string;
    updatedAt: string;
    assigneeUserId?: string | null | undefined;
    dueOn?: string | null | undefined;
    description?: string | null | undefined;
    resolvedAt?: string | null | undefined;
  }[];
};

export async function loadActivityLogs(
  tx: ReadTransaction,
  users: User[]
): Promise<Array<LoadedActivityLog>> {
  const context = await loadLoaderContext(tx, users);
  const activityLogs = await activityLogOps.list(tx);

  const loadedLogs: Array<LoadedActivityLog> = [];
  for (const log of activityLogs) {
    const loadedLog = loadActivityLog(context, log);
    if (loadedLog) {
      loadedLogs.push(loadedLog);
    }
  }
  return loadedLogs;
}

async function loadLoaderContext(tx: ReadTransaction, users: User[]) {
  return {
    comments: await commentOps.list(tx),
    issues: await issueOps.list(tx),
    commentThreads: await commentThreadOps.list(tx),
    discussions: await discussionOps.list(tx),
    markupShapes: await markupShapeOps.list(tx),
    drawingSetPages: await drawingSetPageOps.list(tx),
    drawingSets: await drawingSetOps.list(tx),
    drawingAreas: await drawingAreaOps.list(tx),
    markupSessions: await markupSessionOps.list(tx),
    punchListItems: await PunchListItem.list(tx),
    users: users,
  };
}

function loadActivityLog(
  context: LoaderContext,
  log: ActivityLog
): LoadedActivityLog | null {
  switch (log.type) {
    case 'generic':
      return loadGeneric(context, log);
    case 'add_comment':
      return loadAddComment(context, log);
    case 'add_thread':
      return loadAddThread(context, log);
    case 'add_issue':
      return loadAddIssue(context, log);
    case 'resolve_thread':
      return loadResolveThread(context, log);
    case 'reopen_thread':
      return loadReopenThread(context, log);
    case 'add_user_to_issue':
      return loadAddUserToIssue(context, log);
    case 'remove_user_from_issue':
      return loadRemoveUserFromIssue(context, log);
    case 'markup_session':
      return loadMarkupSession(context, log);
    case 'punch_list_item_create':
      return loadPunchListItemCreate(context, log);
    case 'punch_list_item_in_review':
      return loadPunchListItemInReview(context, log);
    case 'punch_list_item_open':
      return loadPunchListItemOpen(context, log);
    case 'punch_list_item_resolved':
      return loadPunchListItemResolved(context, log);
  }
}

function loadGeneric(
  context: LoaderContext,
  log: Generic
): LoadedGeneric | null {
  const user = context.users.find((u) => u.id === log.userId);

  if (!user) return null;

  return {
    id: log.id,
    type: 'generic',
    createdAt: log.createdAt,
    message: log.message,
    redirectId: log.redirectId,
    user: user,
  };
}

function loadAddComment(
  context: LoaderContext,
  log: AddComment
): LoadedAddComment | null {
  const comment = context.comments.find((c) => c.id === log.commentId);
  const commentThread = context.commentThreads.find(
    (t) => t.id === comment?.commentThreadId
  );
  const user = context.users.find((u) => u.id === log.userId);

  const discussion = context.discussions.find(
    (d) => d.id === commentThread?.discussionId
  );
  const markupShape = context.markupShapes.find(
    (s) =>
      s.id === discussion?.discussableId &&
      discussion?.discussableType === 'MarkupShape'
  );
  const issue = context.issues.find((i) => i.id === markupShape?.issueId);
  const drawingSetPage = context.drawingSetPages.find(
    (p) => p.id === markupShape?.drawingSetPageId
  );
  const drawingSet = context.drawingSets.find(
    (s) => s.id === drawingSetPage?.drawingSetId
  );

  if (!comment || !commentThread) {
    // this is expected if the comment thread has been deleted
    // we probably want to filter out the logs but for now they're here and we filter the comment/thread in the schema
    return null;
  }

  if (!user) {
    console.warn('Missing data for AddComment', log);
    return null;
  }

  const baseComment = {
    id: log.id,
    createdAt: log.createdAt,
    issue: issue,
    comment: comment,
    commentThread: commentThread,
    drawingSetPage: drawingSetPage,
    drawingSet: drawingSet,
    user: user,
    markupShape: markupShape,
  };

  const commentThreadComments = sortBy(
    context.comments.filter(
      (c) => c.commentThreadId === baseComment.commentThread.id
    ),
    'createdAt'
  );
  const commentIndex = commentThreadComments.findIndex(
    (c) => c.id === baseComment.comment.id
  );
  const parentComment =
    commentIndex > 0 ? commentThreadComments[commentIndex - 1] : null;
  const parentCommenter =
    context.users.find((u) => u.id === parentComment?.commenterId) || null;

  if (!parentComment || !parentCommenter) {
    console.warn('Missing data for AddComment', log);
    return null;
  }

  return {
    ...baseComment,
    type: 'add_comment',
    parentComment: parentComment,
    parentCommenter: parentCommenter,
  };
}

function loadResolveThread(
  context: LoaderContext,
  log: ResolveThread
): LoadedResolveThread | null {
  const commentThread = context.commentThreads.find(
    (t) => t.id === log.commentThreadId
  );
  const user = context.users.find((u) => u.id === log.userId);

  const discussion = context.discussions.find(
    (d) => d.id === commentThread?.discussionId
  );
  const markupShape = context.markupShapes.find(
    (s) =>
      s.id === discussion?.discussableId &&
      discussion?.discussableType === 'MarkupShape'
  );
  const issue = context.issues.find((i) => i.id === markupShape?.issueId);
  const drawingSetPage = context.drawingSetPages.find(
    (p) => p.id === markupShape?.drawingSetPageId
  );
  const drawingSet = context.drawingSets.find(
    (s) => s.id === drawingSetPage?.drawingSetId
  );

  if (!commentThread) {
    // this is expected if the comment thread has been deleted
    // we probably want to filter out the logs but for now they're here and we filter the comment/thread in the schema
    return null;
  }

  if (!issue || !user) {
    console.warn('Missing data for ResolveThread', log);
    return null;
  }

  return {
    id: log.id,
    type: 'resolve_thread',
    createdAt: log.createdAt,
    issue: issue,
    commentThread: commentThread,
    drawingSetPage: drawingSetPage,
    drawingSet: drawingSet,
    user: user,
  };
}

function loadReopenThread(
  context: LoaderContext,
  log: ReopenThread
): LoadedReopenThread | null {
  const commentThread = context.commentThreads.find(
    (t) => t.id === log.commentThreadId
  );
  const user = context.users.find((u) => u.id === log.userId);

  const discussion = context.discussions.find(
    (d) => d.id === commentThread?.discussionId
  );
  const markupShape = context.markupShapes.find(
    (s) =>
      s.id === discussion?.discussableId &&
      discussion?.discussableType === 'MarkupShape'
  );
  const issue = context.issues.find((i) => i.id === markupShape?.issueId);
  const drawingSetPage = context.drawingSetPages.find(
    (p) => p.id === markupShape?.drawingSetPageId
  );
  const drawingSet = context.drawingSets.find(
    (s) => s.id === drawingSetPage?.drawingSetId
  );

  if (!commentThread) {
    // this is expected if the comment thread has been deleted
    // we probably want to filter out the logs but for now they're here and we filter the comment/thread in the schema
    return null;
  }

  if (!commentThread || !user) {
    console.warn('Missing data for ReopenThread', log);
    return null;
  }

  return {
    id: log.id,
    type: 'reopen_thread',
    createdAt: log.createdAt,
    issue: issue,
    commentThread: commentThread,
    drawingSetPage: drawingSetPage,
    drawingSet: drawingSet,
    user: user,
  };
}

function loadAddThread(
  context: LoaderContext,
  log: AddThread
): LoadedAddThread | null {
  const comment = context.comments.find((c) => c.id === log.commentId);
  const commentThread = context.commentThreads.find(
    (t) => t.id === comment?.commentThreadId
  );
  const user = context.users.find((u) => u.id === log.userId);

  const discussion = context.discussions.find(
    (d) => d.id === commentThread?.discussionId
  );
  const markupShape = context.markupShapes.find(
    (s) =>
      s.id === discussion?.discussableId &&
      discussion?.discussableType === 'MarkupShape'
  );
  const issue = context.issues.find((i) => i.id === markupShape?.issueId);
  const drawingSetPage = context.drawingSetPages.find(
    (p) => p.id === markupShape?.drawingSetPageId
  );
  const drawingSet = context.drawingSets.find(
    (s) => s.id === drawingSetPage?.drawingSetId
  );

  if (!comment || !commentThread) {
    // this is expected if the comment thread has been deleted
    // we probably want to filter out the logs but for now they're here and we filter the comment/thread in the schema
    return null;
  }

  const punchListItem = context.punchListItems.find(
    (x) =>
      discussion?.discussableType === 'PunchListItem' &&
      x.id === discussion?.discussableId
  );

  if (!discussion || !user) {
    return null;
  }

  return {
    id: log.id,
    type: 'add_thread',
    createdAt: log.createdAt,
    discussion,
    punchListItem,
    issue: issue,
    comment: comment,
    commentThread: commentThread,
    drawingSetPage: drawingSetPage,
    drawingSet: drawingSet,
    user: user,
    markupShape: markupShape,
  };
}

function loadMarkupSession(
  context: LoaderContext,
  log: MarkupSessionLog
): LoadedMarkupSessionLog | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const markupSession = context.markupSessions.find(
    (x) => x.id === log.markupSessionId
  );

  if (!issue || !markupSession || !user) {
    console.warn('Missing data for MarkupSessionLog', log);
    return null;
  }

  return {
    id: log.id,
    type: 'markup_session',
    createdAt: log.createdAt,
    user: user,
    issue: issue,
    markupSession: markupSession,
  };
}

function loadAddIssue(
  context: LoaderContext,
  log: AddIssue
): LoadedAddIssue | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);

  if (!issue || !user) {
    console.warn('Missing data for AddIssue', log);
    return null;
  }

  return {
    id: log.id,
    type: 'add_issue',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
  };
}

function loadAddUserToIssue(
  context: LoaderContext,
  log: AddUserToIssue
): LoadedAddUserToIssue | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const toUser = context.users.find((u) => u.id === log.toUserId);

  if (!issue || !user || !toUser) {
    console.warn('Missing data for IssueMembership', log);
    return null;
  }

  return {
    id: log.id,
    type: 'add_user_to_issue',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    toUser: toUser,
  };
}

function loadRemoveUserFromIssue(
  context: LoaderContext,
  log: RemoveUserFromIssue
): LoadedRemoveUserFromIssue | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const toUser = context.users.find((u) => u.id === log.toUserId);

  if (!issue || !user || !toUser) {
    console.warn('Missing data for IssueMembership', log);
    return null;
  }

  return {
    id: log.id,
    type: 'remove_user_from_issue',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    toUser: toUser,
  };
}

function loadPunchListItemCreate(
  context: LoaderContext,
  log: PunchListItemCreate
): LoadedPunchListItemCreate | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const punchListItem = context.punchListItems.find(
    (x) => x.id === log.punchListItemId
  );

  if (!issue || !user || !punchListItem) {
    console.warn('Missing data for PunchListItemCreate', log);
    return null;
  }

  return {
    id: log.id,
    type: 'punch_list_item_create',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    punchListItem: punchListItem,
  };
}

function loadPunchListItemInReview(
  context: LoaderContext,
  log: PunchListItemInReview
): LoadedPunchListItemInReview | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const punchListItem = context.punchListItems.find(
    (x) => x.id === log.punchListItemId
  );

  if (!issue || !user || !punchListItem) {
    console.warn('Missing data for PunchListItemInReview', log);
    return null;
  }

  return {
    id: log.id,
    type: 'punch_list_item_in_review',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    punchListItem: punchListItem,
  };
}

function loadPunchListItemOpen(
  context: LoaderContext,
  log: PunchListItemOpen
): LoadedPunchListItemOpen | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const punchListItem = context.punchListItems.find(
    (x) => x.id === log.punchListItemId
  );

  if (!issue || !user || !punchListItem) {
    console.warn('Missing data for PunchListItemOpen', log);
    return null;
  }

  return {
    id: log.id,
    type: 'punch_list_item_open',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    punchListItem: punchListItem,
  };
}

function loadPunchListItemResolved(
  context: LoaderContext,
  log: PunchListItemResolved
): LoadedPunchListItemResolved | null {
  const issue = context.issues.find((i) => i.id === log.issueId);
  const user = context.users.find((u) => u.id === log.userId);
  const punchListItem = context.punchListItems.find(
    (x) => x.id === log.punchListItemId
  );

  if (!issue || !user || !punchListItem) {
    console.warn('Missing data for PunchListItemResolved', log);
    return null;
  }

  return {
    id: log.id,
    type: 'punch_list_item_resolved',
    createdAt: log.createdAt,
    issue: issue,
    user: user,
    punchListItem: punchListItem,
  };
}
