import { generate, ReadTransaction, WriteTransaction } from '@rocicorp/rails';
import { compact, sortBy, uniq } from 'lodash';
import { allUsersWithCompany } from '../user/ops';
import {
  submittalApproverDocumentSchema,
  submittalApproverSchema,
  submittalDocumentPageSchema,
  submittalDocumentSchema,
  submittalGroupSchema,
  submittalItemSchema,
  submittalSchema,
  submittalStageSchema,
  submittalSubscriberSchema,
} from './schema';

export const submittalOps = generate('submittals', submittalSchema.parse);
export const submittalApproverOps = generate(
  'submittalApprovers',
  submittalApproverSchema.parse
);
export const submittalDocumentPageOps = generate(
  'submittalDocumentPages',
  submittalDocumentPageSchema.parse
);
export const submittalDocumentOps = generate(
  'submittalDocuments',
  submittalDocumentSchema.parse
);
export const submittalApproverDocumentOps = generate(
  'submittalApproverDocuments',
  submittalApproverDocumentSchema.parse
);
export const submittalGroupOps = generate(
  'submittalGroups',
  submittalGroupSchema.parse
);
export const submittalItemOps = generate(
  'submittalItems',
  submittalItemSchema.parse
);
export const submittalStageOps = generate(
  'submittalStages',
  submittalStageSchema.parse
);
export const submittalSubscriberOps = generate(
  'submittalSubscribers',
  submittalSubscriberSchema.parse
);

export async function getWholeSubmittal(
  tx: ReadTransaction,
  args: {
    id?: string | null | undefined;
    users: Awaited<ReturnType<typeof allUsersWithCompany>>;
  }
) {
  if (!args.id) return {};

  const submittal = await submittalOps.mustGet(tx, args.id);

  const submittalGroup = (await submittalGroupOps.list(tx)).find(
    (x) => x.id === submittal.submittalGroupId
  );

  const submittalStages = sortBy(
    (await submittalStageOps.list(tx)).filter((x) => x.submittalId === args.id),
    'dueOn'
  );

  const submittalItems = (await submittalItemOps.list(tx)).filter((i) =>
    submittalStages.map((s) => s.id).includes(i.submittalStageId)
  );

  const submittalApprovers = (await submittalApproverOps.list(tx)).filter((x) =>
    submittalItems.map((x) => x.id).includes(x.submittalItemId)
  );

  const submittalDocuments = (await submittalDocumentOps.list(tx))
    .filter((d) => !!d.submittalId)
    .filter((x) => x.submittalId === args.id);

  const submittalApproverDocuments = (
    await submittalApproverDocumentOps.list(tx)
  )
    .filter((d) => !!d.submittalApproverId)
    .filter((d) =>
      submittalApprovers.map((x) => x.id).includes(d.submittalApproverId || '')
    );

  const managerUser = submittal
    ? args.users.find((u) => u.id === submittal.managerUserId)
    : null;

  const contractorUser = submittal
    ? args.users.find((u) => u.id === submittal.contractorUserId)
    : null;

  const approverUsers = compact(
    uniq((submittalApprovers || []).map((x) => x.userId)).map((uid) =>
      args.users.find((u) => u.id === uid)
    )
  );

  return {
    submittal,
    submittalGroup,
    submittalStages,
    submittalItems,
    submittalApprovers,
    submittalDocuments,
    submittalApproverDocuments,
    managerUser,
    contractorUser,
    approverUsers,
  };
}

export async function createSubmittalGroup(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await submittalGroupOps.set(tx, {
    id: args.id,
    title: args.title,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}

export async function createSubmittalDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await submittalDocumentOps.set(tx, {
    id: args.id,
    title: args.title,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}

export async function createSubmittalApproverDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
  }
) {
  await submittalApproverDocumentOps.set(tx, {
    id: args.id,
    title: args.title,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}
