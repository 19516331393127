import {
  SubmittalApprover,
  SubmittalApproverResponseType,
} from '@/models/submittal-approver';
import { WriteTransaction } from 'replicache';

export default async function respondToSubmittal(
  tx: WriteTransaction,
  args: {
    submittalApproverId: string;
    responseType: SubmittalApproverResponseType;
    now: string;
    comment: string;
    documentIds: string[];
  }
) {
  const approver = await SubmittalApprover.mustGet(
    tx,
    args.submittalApproverId
  );

  await SubmittalApprover.set(tx, {
    ...approver,
    responseType: args.responseType,
    respondedAt: args.now,
    updatedAt: args.now,
    comment: args.comment,
  });
}
