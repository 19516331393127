import { WriteTransaction } from 'replicache';
import { drawingSetPageOps } from '@/models/drawing-set-page/ops';

type UnconfirmDrawingSetPageArgs = { id: string };
export async function unconfirmDrawingSetPage(
  tx: WriteTransaction,
  { id }: UnconfirmDrawingSetPageArgs
) {
  const page = await drawingSetPageOps.mustGet(tx, id);
  await drawingSetPageOps.update(tx, {
    ...page,
    state: 'awaiting_confirmation',
  });
}
