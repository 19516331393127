import { generate, ReadTransaction, WriteTransaction } from '@rocicorp/rails';
import { drawingDisciplineAbbreviationOps } from '../drawing-discipline-abbreviation/ops';
import { drawingDisciplineSchema } from './schema';

export const drawingDisciplineOps = generate(
  'drawingDisciplines',
  drawingDisciplineSchema.parse
);

export const getProjectDisciplines = async (
  tx: ReadTransaction,
  projectId: string
) => {
  const drawingDisciplines = await drawingDisciplineOps.list(tx);
  const drawingDisciplineAbbreviations =
    await drawingDisciplineAbbreviationOps.list(tx);

  return drawingDisciplines
    .filter((drawingDiscipline) => drawingDiscipline.projectId === projectId)
    .sort((a, b) => a.position - b.position)
    .map((discipline) => {
      return {
        ...discipline,
        abbreviations: drawingDisciplineAbbreviations.filter(
          (x) => x.drawingDisciplineId === discipline.id
        ),
      };
    });
};

export type CreateDrawingDisciplineArgs = {
  id: string;
  organizationId: string;
  projectId: string;
  name: string;
  position: number;
  abbreviations: { id: string; name: string }[];
};

export const createDrawingDiscipline = async (
  tx: WriteTransaction,
  createDiscipline: CreateDrawingDisciplineArgs
) => {
  const now = new Date();

  await drawingDisciplineOps.set(tx, {
    ...createDiscipline,
    createdAt: now.toISOString(),
    updatedAt: now.toISOString(),
  });

  await Promise.all(
    createDiscipline.abbreviations.map((abbreviation) =>
      drawingDisciplineAbbreviationOps.set(tx, {
        drawingDisciplineId: createDiscipline.id,
        projectId: createDiscipline.projectId,
        organizationId: createDiscipline.organizationId,
        createdAt: now.toISOString(),
        updatedAt: now.toISOString(),
        ...abbreviation,
      })
    )
  );
};
