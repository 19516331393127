import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from '../application-record';
import { ObjectPool } from '../object-pool';
import { PunchListItem } from '../punch-list-item';
import { User } from '../user/model';

export const activityLogTypes = [
  'generic',
  'add_comment',
  'add_thread',
  'resolve_thread',
  'reopen_thread',
  'add_issue',
  'add_user_to_issue',
  'remove_user_from_issue',
  'markup_session',
  'punch_list_item_create',
  'punch_list_item_open',
  'punch_list_item_in_review',
  'punch_list_item_resolved',
] as const;

export type ActivityLogType = (typeof activityLogTypes)[number];

export class ActivityLog extends ApplicationRecord {
  id: string;
  userId: string;
  createdAt: string;
  type: ActivityLogType;
  punchListItemId?: string | null | undefined;
  message?: string | null | undefined;
  redirectId?: string | null | undefined;

  static prefix = 'activityLogs' as const;

  static schema = z.object({
    id: z.string(),
    userId: z.string(),
    createdAt: z.string().datetime(),
    type: z.enum(activityLogTypes),
    punchListItemId: z.string().nullish(),
    message: z.string().nullish(),
    redirectId: z.string().nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.userId = this.attribute('userId');
    this.createdAt = this.attribute('createdAt');
    this.type = this.attribute('type');
    this.punchListItemId = this.attribute('punchListItemId');
    this.message = this.attribute('message');
    this.redirectId = this.attribute('redirectId');
  }

  get user() {
    return this.belongsTo(User, this.userId);
  }

  get punchListItem() {
    return this.belongsToOptional(PunchListItem, this.punchListItemId);
  }
}
