import { PunchListItem } from '@/models/punch-list-item';
import { WriteTransaction } from 'replicache';

export default async function createPunchListItem(
  tx: WriteTransaction,
  args: {
    id: string;
    description: string | null | undefined;
    projectId: string;
    title: string;
    dueOn: string | null | undefined;
    status: 'open' | 'in_review' | 'resolved';
    topicId: string;
    creatorUserId: string;
    approverUserId: string;
    assigneeUserId: string | null | undefined;
    createdAt: string;
    updatedAt: string;
  }
) {
  await PunchListItem.set(tx, { ...args });
}
