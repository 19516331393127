import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { drawingAreaOps } from '../drawing-area/ops';
import { Project, projectSchema } from './schema';

export const projectOps = generate('projects', projectSchema.parse);

type CreateProject = Project & { drawingAreaId: string };
const createProject = async (
  tx: WriteTransaction,
  createParams: CreateProject
) => {
  const { drawingAreaId, ...project } = createParams;
  await projectOps.set(tx, project);
  await drawingAreaOps.set(tx, {
    id: drawingAreaId,
    projectId: project.id,
    organizationId: project.organizationId,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });
};

const getProjects = projectOps.list;
const findProject = projectOps.get;

export { createProject, findProject, getProjects };
