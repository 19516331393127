import { z } from 'zod';

export type MarkupShape = z.infer<typeof markupShapeSchema>;
export type CommentProps = z.infer<typeof commentPropsSchema>;
export type PunchListItemProps = z.infer<typeof punchListItemPropsSchema>;

export const markupShapeSchema = z.object({
  id: z.string(),
  tldrawId: z.string(),
  drawingSetPageId: z.string(),
  issueId: z.string(),
  props: z.string(),
  rotation: z.number(),
  x: z.number(),
  y: z.number(),
  type: z.string(),
  deleted: z.boolean(),
  lastModifiedOnCanvasId: z.string().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const commentPropsSchema = z.object({
  commentThreadId: z.string().nullish(),
});

export const punchListItemPropsSchema = z.object({
  punchListItemId: z.string().nullish(),
});

export function getCommentProps(shape: MarkupShape): CommentProps | null {
  if (shape.type !== 'comment') {
    console.warn('Attempted to get comment props for non-comment shape');
    return null;
  }

  try {
    const parsedProps = JSON.parse(shape.props);
    return commentPropsSchema.parse(parsedProps);
  } catch (error) {
    console.error('Failed to parse comment props:', error);
    return null;
  }
}

export function getPunchListItemProps(
  shape: MarkupShape
): PunchListItemProps | null {
  if (shape.type !== 'punch-item') {
    console.warn(
      'Attempted to get punch list item props for non-punch-item shape'
    );
    return null;
  }

  try {
    const parsedProps = JSON.parse(shape.props);
    return punchListItemPropsSchema.parse(parsedProps);
  } catch (error) {
    console.error('Failed to parse punch list item props:', error);
    return null;
  }
}
