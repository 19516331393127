import { generate } from '@rocicorp/rails';
import { compact, max } from 'lodash';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { issueOps } from '../issue/ops';
import { pendingAttachmentOps } from '../pending-attachment/ops';
import { User } from '../user/schema';
import {
  rfiQuestionAttachmentSchema,
  RfiResponseAttachment,
  rfiResponseAttachmentSchema,
  rfiResponseSchema,
  rfiSchema,
  RfiStatus,
  rfiSubscriberSchema,
} from './schema';

export const rfiOps = generate('rfis', rfiSchema.parse);
export const rfiResponseOps = generate('rfiResponses', rfiResponseSchema.parse);
export const rfiSubscriberOps = generate(
  'rfiSubscribers',
  rfiSubscriberSchema.parse
);
export const rfiQuestionAttachmentOps = generate(
  'rfiQuestionAttachments',
  rfiQuestionAttachmentSchema.parse
);
export const rfiResponseAttachmentOps = generate(
  'rfiResponseAttachments',
  rfiResponseAttachmentSchema.parse
);

export async function getRfis(tx: ReadTransaction) {
  return await rfiOps.list(tx);
}

export async function getRfi(tx: ReadTransaction, args: { id?: string }) {
  if (!args.id) return;
  return await rfiOps.mustGet(tx, args.id);
}

export async function getSubscribersAndRespondents(
  tx: ReadTransaction,
  args: {
    rfiId: string;
    users: User[];
  }
) {
  let all = await rfiSubscriberOps.list(tx);
  all = all.filter((x) => x.rfiId === args.rfiId);

  const respondents = compact(
    all
      .filter((x) => x.responseRequired)
      .map((x) => {
        return {
          subscriber: x,
          user: args.users.find((u) => u.id === x.userId),
        };
      })
  );

  const subscribers = compact(
    all
      .filter((x) => !x.responseRequired)
      .map((x) => {
        return {
          subscriber: x,
          user: args.users.find((u) => u.id === x.userId),
        };
      })
  );

  return { respondents, subscribers };
}

export type LoadedRfiResponse = Awaited<
  ReturnType<typeof getResponses>
>[number];
export async function getResponses(
  tx: ReadTransaction,
  args: {
    rfiId: string;
    users: User[];
  }
) {
  let subs = await rfiSubscriberOps.list(tx);
  subs = subs.filter((x) => x.rfiId === args.rfiId);

  let all = await rfiResponseOps.list(tx);
  all = all.filter((x) => x.rfiId === args.rfiId);

  const allPendingAttachments = await pendingAttachmentOps.list(tx);
  const allResponseAttachments = await rfiResponseAttachmentOps.list(tx);

  return compact(
    all.map((response) => {
      const responseAttachments = allResponseAttachments
        .filter((a) => a.responseId === response.id)
        .map((attachment) => ({
          ...attachment,
          pendingAttachment: allPendingAttachments.find(
            (a) => a.recordId === attachment.id
          ),
        }));

      const subscriber = subs.find((s) => s.id === response.subscriberId);
      if (!subscriber) return;

      const user = args.users.find((u) => u.id === subscriber.userId);
      if (!user) return;

      return {
        response,
        subscriber: subscriber,
        user: user,
        attachments: responseAttachments,
      };
    })
  );
}

export async function createRfi(
  tx: WriteTransaction,
  args: {
    id: string;
    dueOn?: string | null | undefined;
    openedAt: string;
    question: string;
    status: RfiStatus;
    subject: string;
    createdAt: string;
    updatedAt: string;
    managerUserId: string;
    subscriberUserIds: string[];
    respondentUserIds: string[];
    topicId: string;
  }
) {
  await issueOps.set(tx, {
    id: args.topicId,
    title: args.subject,
    status: 'editing',
    type: 'rfi',
    companyAccessible: true,
    organizationAccessible: true,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });

  const maxNumber = max((await rfiOps.list(tx)).map((rfi) => rfi.number)) || 0;

  await rfiOps.set(tx, {
    id: args.id,
    dueOn: args.dueOn,
    openedAt: args.openedAt,
    question: args.question,
    status: args.status,
    subject: args.subject,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    managerUserId: args.managerUserId,
    closedAt: null,
    number: maxNumber + 1,
    ballInCourtStatus: 'manager',
    topicId: args.topicId,
  });
}

export async function respondToRfi(
  tx: WriteTransaction,
  args: {
    id: string;
    rfiId: string;
    content: string;
    createdAt: string;
    updatedAt: string;
    subscriberId: string;
  }
) {
  await rfiResponseOps.set(tx, {
    id: args.id,
    rfiId: args.rfiId,
    subscriberId: args.subscriberId,
    content: args.content,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    acceptedAt: null,
  });
}

export async function cancelRfi(
  tx: WriteTransaction,
  args: {
    id: string;
  }
) {
  const rfi = await rfiOps.mustGet(tx, args.id);

  await rfiOps.set(tx, {
    ...rfi,
    status: 'cancelled',
  });
}

export async function acceptRfiResponse(
  tx: WriteTransaction,
  args: {
    id: string;
    acceptedAt: string;
  }
) {
  const response = await rfiResponseOps.mustGet(tx, args.id);
  const rfi = await rfiOps.mustGet(tx, response.rfiId);
  await rfiResponseOps.set(tx, { ...response, acceptedAt: args.acceptedAt });
  await rfiOps.set(tx, { ...rfi, status: 'closed' });
}

export async function createRfiResponseAttachment(
  tx: WriteTransaction,
  args: RfiResponseAttachment
) {
  await tx.set('rfiResponseAttachments/' + args.id, args);
}

export async function destroyRfiResponseAttachment(
  tx: WriteTransaction,
  args: { id: string }
) {
  await tx.del('rfiResponseAttachments/' + args.id);
}
