import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { commentOps } from '../comment/ops';
import {
  Comment,
  MediaAttachment,
  ScreenRecordingInfo,
} from '../comment/schema';
import { commentThreadSchema } from './schema';

export const commentThreadOps = generate(
  'commentThreads',
  commentThreadSchema.parse
);

type StartCommentThreadArgs = {
  discussionId: string;
  commentThreadId: string;
  commentId: string;
  commenterId: string;
  commentContent: string;
  commentCreatedAt: string;
  mediaAttachments: Array<MediaAttachment>;

  // These are not used by the local mutation but is used by the server.
  // While we're waiting for the poke and refresh, it appears as if there's no media for the comment.
  signedMediaAttachmentFileIds: Array<string>;
  signedScreenRecordingId: string | null;
};

export async function startCommentThread(
  tx: WriteTransaction,
  {
    discussionId,
    commentThreadId,
    commentId,
    commenterId,
    commentContent,
    commentCreatedAt,
    mediaAttachments,
  }: StartCommentThreadArgs
) {
  const commentThread = {
    id: commentThreadId,
    discussionId,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  };
  await commentThreadOps.set(tx, commentThread);

  const comment: Comment = {
    id: commentId,
    content: commentContent,
    commentThreadId,
    commenterId,
    mediaAttachments,
    screenRecordingInfo: null,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  };
  await commentOps.set(tx, comment);
}

type AddCommentToThreadArgs = {
  commentId: string;
  commentThreadId: string;
  commentContent: string;
  commenterId: string;
  mediaAttachments: Array<MediaAttachment>;
  screenRecordingInfo: ScreenRecordingInfo | null;
  commentCreatedAt: string;

  // These are not used by the local mutation but is used by the server.
  // While we're waiting for the poke and refresh, it appears as if there's no media for the comment.
  signedMediaAttachmentFileIds: Array<string>;
  signedScreenRecordingId: string | null;
};

export async function addCommentToThread(
  tx: WriteTransaction,
  {
    commentId,
    commentThreadId,
    commentContent,
    commenterId,
    mediaAttachments,
    screenRecordingInfo,
    commentCreatedAt,
  }: AddCommentToThreadArgs
) {
  const comment: Comment = {
    id: commentId,
    content: commentContent,
    commentThreadId,
    commenterId,
    mediaAttachments,
    screenRecordingInfo,
    createdAt: commentCreatedAt,
    updatedAt: commentCreatedAt,
  };

  await commentOps.set(tx, comment);
}

type ResolveCommentThreadArgs = {
  commentThreadId: string;
  resolvedAt: string;
  resolvedById: string;
  updatedAt: string;
};

export async function resolveCommentThread(
  tx: WriteTransaction,
  {
    commentThreadId,
    resolvedAt,
    resolvedById,
    updatedAt,
  }: ResolveCommentThreadArgs
) {
  const thread = await commentThreadOps.mustGet(tx, commentThreadId);
  await commentThreadOps.update(tx, {
    ...thread,
    resolvedAt,
    resolvedById,
    updatedAt,
  });
}

type ReopenCommentThreadArgs = {
  commentThreadId: string;
  updatedAt: string;
};

export async function reopenCommentThread(
  tx: WriteTransaction,
  { commentThreadId, updatedAt }: ReopenCommentThreadArgs
) {
  const thread = await commentThreadOps.mustGet(tx, commentThreadId);
  await commentThreadOps.update(tx, {
    ...thread,
    resolvedAt: null,
    resolvedById: null,
    updatedAt,
  });
}
