import { generate, ReadTransaction, WriteTransaction } from '@rocicorp/rails';
import { compact, sortBy } from 'lodash';
import { notificationSchema } from './schema';

export const notificationOps = generate(
  'notifications',
  notificationSchema.parse
);

export async function loadNotifications(tx: ReadTransaction) {
  return sortBy(compact(await notificationOps.list(tx)), 'createdAt').reverse();
}

export async function markNotificationAsRead(
  tx: WriteTransaction,
  args: { id: string }
) {
  const notification = await notificationOps.mustGet(tx, args.id);
  await notificationOps.set(tx, {
    ...notification,
    readAt: new Date().toISOString(),
  });
}
