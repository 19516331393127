import { startOfMonth } from 'date-fns';
import { sortBy } from 'lodash';
import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';
import { Photo } from './photo';

export const systemManagedTypes = ['daily_logs', 'camera_roll'] as const;

export type SystemManagedType = (typeof systemManagedTypes)[number];

export class PhotoAlbum extends ApplicationRecord {
  id: string;
  title: string;
  photosCount: number;
  coverPhotoId?: string | null;
  createdAt: string;
  updatedAt: string;
  lastCapturedAt?: string | null | undefined;
  systemManagedType?: SystemManagedType | null | undefined;

  static prefix = 'photoAlbums' as const;

  static schema = z.object({
    id: z.string(),
    title: z.string(),
    photosCount: z.number().default(0),
    coverPhotoId: z.string().nullish(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
    lastCapturedAt: z.string().datetime().nullish(),
    systemManagedType: z.enum(systemManagedTypes).nullish(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.title = this.attribute('title');
    this.photosCount = this.attribute('photosCount');
    this.coverPhotoId = this.attribute('coverPhotoId');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
    this.lastCapturedAt = this.attribute('lastCapturedAt');
    this.systemManagedType = this.attribute('systemManagedType');
  }

  get photos() {
    return sortBy(this.hasMany(Photo, 'photoAlbumId'), 'createdAt');
  }

  get startOfMonth() {
    return startOfMonth(this.createdAt).toISOString();
  }

  get coverPhoto() {
    return this.belongsToOptional(Photo, this.coverPhotoId);
  }

  get hasPhotos() {
    return this.photosCount > 0;
  }

  get capturedOrCreatedAt() {
    return this.lastCapturedAt ?? this.createdAt;
  }

  get isSystemManaged() {
    return !!this.systemManagedType;
  }

  get canModifyPhotos() {
    if (this.systemManagedType === 'daily_logs') return false;

    return true;
  }

  get canRenameAlbum() {
    if (this.systemManagedType === 'daily_logs') return false;
    if (this.systemManagedType === 'camera_roll') return false;

    return true;
  }
}
