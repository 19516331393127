import { PunchListItem } from '@/models/punch-list-item';
import { WriteTransaction } from 'replicache';

export default async function changePunchListItemStatus(
  tx: WriteTransaction,
  args: {
    id: string;
    status: 'open' | 'in_review' | 'resolved';
    updatedAt: string;
  }
) {
  const item = await PunchListItem.mustGet(tx, args.id);
  await PunchListItem.set(tx, {
    ...item,
    status: args.status,
    updatedAt: args.updatedAt,
  });
}
