import { groupJoin } from '@/lib/query';
import { formatNameAlt } from '@/lib/utils';
import { generate, WriteTransaction } from '@rocicorp/rails';
import { compact, sortBy } from 'lodash';
import { ReadTransaction } from 'replicache';
import { companyOps } from '../company/ops';
import { companyMembershipOps } from '../company_membership/ops';
import { organizationMembershipOps } from '../organization-membership/ops';
import { ProjectsCompaniesGrant } from '../projects-companies-grant';
import { userSchema } from './schema';

export const userOps = generate('users', userSchema.parse);

export async function updateMyEmail(
  tx: WriteTransaction,
  args: {
    userId: string;
    email: string;
    updatedAt: string;
  }
) {
  await userOps.set(tx, {
    ...(await userOps.mustGet(tx, args.userId)),
    email: args.email,
    updatedAt: args.updatedAt,
  });
}

export async function createUserForCompany(
  tx: WriteTransaction,
  args: {
    userId: string;
    companyId: string;
    companyMembershipId: string;
    organizationId: string;
    email: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    phoneNumber?: string | null | undefined;
  }
) {
  await userOps.set(tx, {
    id: args.userId,
    email: args.email,
    firstName: args.firstName,
    lastName: args.lastName,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    otpEnabled: false,
    verified: false,
    phoneNumber: args.phoneNumber,
  });
  await companyMembershipOps.set(tx, {
    id: args.companyMembershipId,
    organizationId: args.organizationId,
    userId: args.userId,
    companyId: args.companyId,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
    role: 'sub_contractor',
  });
}

export async function allUsersWithCompany(tx: ReadTransaction) {
  let companies = await companyOps.list(tx);
  let users = await userOps.list(tx);
  let companyMemberships = await companyMembershipOps.list(tx);

  return users.map((user) => {
    const companyMembership = companyMemberships.find(
      (x) => x.userId === user.id
    );
    const company = companies.find(
      (x) => x.id === companyMembership?.companyId
    );

    return {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      email: user.email,
      fullName: formatNameAlt({
        firstName: user.firstName,
        lastName: user.lastName,
        company: company?.name,
      }),
      avatarUrl: user.avatarUrl,
      user,
      companyMembership,
      company,
    };
  });
}
export type UsersWithCompanies = Awaited<
  ReturnType<typeof allUsersWithCompany>
>;

export async function getAllCompaniesAndUsers(tx: ReadTransaction) {
  let companies = await companyOps.list(tx);
  let users = await userOps.list(tx);
  let companyMemberships = await companyMembershipOps.list(tx);

  return sortBy(
    companies.map((c) => {
      return {
        company: c,
        users: sortBy(
          compact(
            companyMemberships
              .filter((m) => c.id === m.companyId)
              .map((m) => users.find((u) => u.id === m.userId))
          ),
          (x) => x.email.toLowerCase()
        ),
        companyMemberships: companyMemberships.filter(
          (m) => m.companyId === c.id
        ),
      };
    }),
    (x) => x.company.name.toLowerCase()
  );
}

export async function getAllUsersForSelect(
  tx: ReadTransaction,
  args: { projectId: string }
) {
  const organizationMemberships = await organizationMembershipOps.list(tx);
  const allUsers = await userOps.list(tx);

  const organizationUsers = compact(
    groupJoin(
      organizationMemberships,
      allUsers,
      (membership, user) => membership.userId === user.id,
      'users'
    ).map((membership) => membership.users[0])
  ).map((user) => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
    company: null,
  }));

  const externalUsers = (await getCompanyUsersForProject(tx, args.projectId))
    .map(({ company, users }) =>
      users.map((user) => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        company: company.name,
      }))
    )
    .flat();

  return [...organizationUsers, ...externalUsers];
}

export async function getCompanyUsersForProject(
  tx: ReadTransaction,
  projectId: string
) {
  let companies = await companyOps.list(tx);
  let users = await userOps.list(tx);
  let projectsCompaniesGrants = await ProjectsCompaniesGrant.list(tx);
  let companyMemberships = await companyMembershipOps.list(tx);

  companies = compact(
    projectsCompaniesGrants
      .filter((m) => m.projectId === projectId)
      .map((m) => companies.find((c) => c.id === m.companyId))
  );

  return sortBy(
    companies.map((c) => {
      return {
        company: c,
        users: sortBy(
          compact(
            companyMemberships
              .filter((m) => c.id === m.companyId)
              .map((m) => users.find((u) => u.id === m.userId))
          ),
          (x) => x.email.toLowerCase()
        ),
        projectMembership: projectsCompaniesGrants.find(
          (m) => m.companyId === c.id && m.projectId === projectId
        ),
        companyMemberships: companyMemberships.filter(
          (m) => m.companyId === c.id
        ),
      };
    }),
    (x) => x.company.name.toLowerCase()
  );
}
