import { Submittal, SubmittalType } from '@/models/submittal';
import { SubmittalGroup } from '@/models/submittal-group';
import { SubmittalSubscriber } from '@/models/submittal-subscriber';
import { uniq } from 'lodash';
import { WriteTransaction } from 'replicache';

export default async function updateSubmittalOverview(
  tx: WriteTransaction,
  args: {
    id: string;
    now: string;
    managerUserId: string;
    contractorUserId: string | null;
    submittalGroupIdOrNewName: string | null | undefined;
    idWhenCreatingNewSubmittalGroup: string;
    type: SubmittalType | '--';
    subscribers: { id: string; userId: string }[];
  }
) {
  const submittal = await Submittal.get(tx, args.id);
  if (!submittal) return;

  const group = await SubmittalGroup.get(
    tx,
    args.submittalGroupIdOrNewName || ''
  );

  if (
    !group &&
    args.submittalGroupIdOrNewName &&
    args.submittalGroupIdOrNewName.length > 0
  ) {
    await SubmittalGroup.set(tx, {
      id: args.idWhenCreatingNewSubmittalGroup,
      title: args.submittalGroupIdOrNewName,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }

  await Submittal.set(tx, {
    ...submittal,
    submittalGroupId: group?.id,
    type: args.type === '--' ? null : args.type,
    managerUserId: args.managerUserId,
    contractorUserId: args.contractorUserId,
    updatedAt: args.now,
  });

  const subscribers = (await SubmittalSubscriber.list(tx)).filter(
    (x) => x.submittalId === args.id
  );

  const subscriberUserIds = uniq(
    [
      subscribers.map((x) => x.userId),
      args.subscribers.map((x) => x.userId),
    ].flat()
  );

  for (const subscriberUserId of subscriberUserIds) {
    const existing = subscribers.find((x) => x.userId === subscriberUserId);
    const submitted = args.subscribers.find(
      (x) => x.userId === subscriberUserId
    );

    if (submitted && !existing) {
      await SubmittalSubscriber.set(tx, {
        ...submitted,
        submittalId: args.id,
        createdAt: args.now,
        updatedAt: args.now,
      });
    } else if (existing && !submitted) {
      await SubmittalSubscriber.delete(tx, existing.id);
    } else if (existing && submitted) {
      await SubmittalSubscriber.set(tx, {
        ...existing,
        updatedAt: args.now,
      });
    } else if (!existing && !submitted) {
      // wtf
    }
  }
}
