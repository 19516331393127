import { ReadonlyJSONObject } from '@rocicorp/rails';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { DrawingArea } from './drawing-area';
import { DrawingSetPage } from './drawing-set-page';
import { ObjectPool } from './object-pool';

const processingStates = [
  'initialized',
  'processing',
  'finished',
  'error',
] as const;

type DrawingSetProcessingState = (typeof processingStates)[number];

export class DrawingSet extends ApplicationRecord {
  id: string;
  name: string;
  receivedOn: string;
  defaultSheetDate: string;
  processingState: DrawingSetProcessingState;
  revisionNumber: string;
  description: string;
  drawingAreaId: string;
  thumbnailUrl: string;
  createdAt: string;
  updatedAt: string;

  static prefix = 'drawingSets' as const;

  static schema = z.object({
    id: z.string(),
    name: z.string(),
    receivedOn: z.string().date().optional().nullable(),
    defaultSheetDate: z.string().date().optional().nullable(),
    processingState: z.enum(processingStates),
    revisionNumber: z.string().max(8).optional().nullable(),
    description: z.string().max(1024).optional().nullable(),
    drawingAreaId: z.string(),
    thumbnailUrl: z.string().optional().nullable(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.name = this.attribute('name');
    this.receivedOn = this.attribute('receivedOn');
    this.defaultSheetDate = this.attribute('defaultSheetDate');
    this.processingState = this.attribute('processingState');
    this.revisionNumber = this.attribute('revisionNumber');
    this.description = this.attribute('description');
    this.drawingAreaId = this.attribute('drawingAreaId');
    this.thumbnailUrl = this.attribute('thumbnailUrl');
    this.createdAt = this.attribute('createdAt');
    this.updatedAt = this.attribute('updatedAt');
  }

  get drawingArea() {
    return this.belongsTo(DrawingArea, this.drawingAreaId);
  }

  get drawingSetPages() {
    return this.hasMany(DrawingSetPage, 'drawingSetId').filter(
      (x) => !x.deletedAt
    );
  }
}
