import { generate, ReadTransaction } from '@rocicorp/rails';
import { uniq } from 'lodash';
import { WriteTransaction } from 'replicache';
import { drawingSetPageOps } from '../drawing-set-page/ops';
import { drawingSetSchema } from './schema';

const drawingSetOps = generate('drawingSets', drawingSetSchema.parse);
export default drawingSetOps;

export async function getDrawingSetsForArea(
  tx: ReadTransaction,
  drawingAreaId: string
) {
  const allSets = await drawingSetOps.list(tx);
  return allSets.filter((set) => set.drawingAreaId === drawingAreaId);
}

export async function getDrawingSetsByIds(
  tx: ReadTransaction,
  args: { ids: string[] }
) {
  const ids = uniq(args.ids);
  return (await drawingSetOps.list(tx)).filter((set) => ids.includes(set.id));
}

export async function getProcessingDrawingSetsForDrawingArea(
  tx: ReadTransaction,
  drawingAreaId: string
) {
  let sets = await getDrawingSetsForArea(tx, drawingAreaId);
  let pages = await drawingSetPageOps.list(tx);

  return sets.filter((set) => {
    const setProcessing =
      set.processingState === 'initialized' ||
      set.processingState === 'processing';

    const setPages = pages.filter((page) => page.drawingSetId === set.id);
    const setPagesProcessing = setPages.some(
      (page) =>
        page.state === 'initialized' || page.state === 'extracting_metadata'
    );

    return setProcessing || setPagesProcessing;
  });
}

export async function updateDrawingSet(
  tx: WriteTransaction,
  args: {
    id: string;
    drawingAreaId: string;
    name: string;
    description: string;
    receivedOn: string;
    updatedAt: string;
  }
) {
  let set = await drawingSetOps.mustGet(tx, args.id);
  await drawingSetOps.set(tx, {
    ...set,
    name: args.name,
    description: args.description,
    receivedOn: args.receivedOn,
  });
}
