import { dirname, join, removePrefix, startsWithDir } from '@/lib/file-utils';
import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import {
  documentSchema,
  folderSchema,
  usersDocumentsGrantSchema,
  usersFoldersGrantSchema,
} from './schema';

export const folderOps = generate('folders', folderSchema.parse);
export const documentOps = generate('documents', documentSchema.parse);
export const usersDocumentsGrantOps = generate(
  'usersDocumentsGrants',
  usersDocumentsGrantSchema.parse
);
export const usersFoldersGrantOps = generate(
  'usersFoldersGrants',
  usersFoldersGrantSchema.parse
);

export async function shareDocument(
  tx: WriteTransaction,
  args: {
    documentId: string;
    grants: { userId: string; id: string }[];
  }
) {
  const grants = await usersDocumentsGrantOps.list(tx);
  for (const grant of grants) {
    await usersDocumentsGrantOps.delete(tx, grant.id);
  }
  for (const grant of args.grants) {
    await usersDocumentsGrantOps.set(tx, {
      ...grant,
      documentId: args.documentId,
    });
  }
}

export async function createDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    uploaderId: string;
    path: string;
    mimeType: string;
    sizeBytes: number;
    modifiedAt: string;
    updatedAt: string;
    createdAt: string;
  }
) {
  await documentOps.set(tx, {
    id: args.id,
    uploaderId: args.uploaderId,
    path: join(args.path),
    mimeType: args.mimeType,
    sizeBytes: args.sizeBytes,
    modifiedAt: args.modifiedAt,
    updatedAt: args.updatedAt,
    createdAt: args.createdAt,
    deletedAt: null,
  });
}

export async function addFolder(
  tx: WriteTransaction,
  args: {
    folderId: string;
    documentId: string;
    grantId: string;
    userId: string;
    path: string;
    now: string;
    organizationGrant: boolean;
    projectGrant: boolean;
    shareWithUserIds: { grantId: string; userId: string }[];
  }
) {
  await folderOps.set(tx, {
    id: args.folderId,
    path: join(args.path),
    organizationGrant: args.organizationGrant,
    projectGrant: args.projectGrant,
    createdAt: args.now,
    updatedAt: args.now,
  });

  await documentOps.set(tx, {
    id: args.documentId,
    uploaderId: args.userId,
    path: join([args.path, '.keep']),
    updatedAt: args.now,
    createdAt: args.now,
    deletedAt: null,
    mimeType: 'application/octet-stream',
    sizeBytes: 0,
    modifiedAt: new Date(0).toISOString(),
  });

  // folder creator grant
  await usersFoldersGrantOps.set(tx, {
    id: args.grantId,
    folderId: args.folderId,
    userId: args.userId,
    createdAt: args.now,
    updatedAt: args.now,
  });

  // share with users
  for (const grant of args.shareWithUserIds) {
    await usersFoldersGrantOps.set(tx, {
      id: grant.grantId,
      folderId: args.folderId,
      userId: grant.userId,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}

export async function shareFolder(
  tx: WriteTransaction,
  args: {
    folderId: string;
    now: string;
    organizationGrant: boolean;
    projectGrant: boolean;
    shareWithUserIds: { grantId: string; userId: string }[];
  }
) {
  const folder = await folderOps.mustGet(tx, args.folderId);
  await folderOps.set(tx, {
    ...folder,
    organizationGrant: args.organizationGrant,
    projectGrant: args.projectGrant,
  });

  const existingGrants = (await usersFoldersGrantOps.list(tx)).filter(
    (x) => x.folderId === args.folderId
  );

  for (const grant of existingGrants) {
    await usersFoldersGrantOps.delete(tx, grant.id);
  }

  for (const grant of args.shareWithUserIds) {
    await usersFoldersGrantOps.set(tx, {
      id: grant.grantId,
      folderId: args.folderId,
      userId: grant.userId,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }
}

export async function renameDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    newName: string;
  }
) {
  const doc = await documentOps.mustGet(tx, args.id);
  await documentOps.set(tx, {
    ...doc,
    path: join([dirname(doc.path), args.newName]),
  });
}

export async function deleteDocument(
  tx: WriteTransaction,
  args: {
    id: string;
    deletedAt: string;
  }
) {
  const doc = await documentOps.mustGet(tx, args.id);
  await documentOps.set(tx, {
    ...doc,
    deletedAt: args.deletedAt,
  });
}

export async function renameFolder(
  tx: WriteTransaction,
  args: {
    from: string;
    to: string;
  }
) {
  const docs = await documentOps.list(tx);
  for (const doc of docs) {
    const newPath = join([args.to, removePrefix(doc.path, args.from)]);

    if (newPath !== doc.path) {
      await documentOps.set(tx, { ...doc, path: newPath });
    }
  }
}

export async function deleteFolder(
  tx: WriteTransaction,
  args: {
    prefix: string;
    deletedAt: string;
  }
) {
  const docs = await documentOps.list(tx);
  for (const doc of docs) {
    if (startsWithDir(doc.path, args.prefix)) {
      await documentOps.delete(tx, doc.id);
    }
  }
}
