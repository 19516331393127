import { generate, ReadTransaction } from '@rocicorp/rails';
import { drawingAreaSchema } from './schema';

export const drawingAreaOps = generate('drawingAreas', drawingAreaSchema.parse);

export const getProjectDrawingArea = async (
  tx: ReadTransaction,
  projectId: string
) => {
  const drawingAreas = await drawingAreaOps.list(tx);
  const drawingArea = drawingAreas.find(
    (drawingArea) => drawingArea.projectId === projectId
  );
  return drawingArea;
};
