import { ReadonlyJSONObject } from 'replicache';
import { z } from 'zod';
import { ApplicationRecord } from './application-record';
import { ObjectPool } from './object-pool';

export class SyncedFeature extends ApplicationRecord {
  id: string;
  feature: string;
  isSynced: boolean;

  static prefix = 'syncedFeatures' as const;

  static schema = z.object({
    id: z.string(),
    feature: z.string(),
    isSynced: z.boolean(),
  });

  constructor(
    id: string,
    attributes: ReadonlyJSONObject,
    objectPool: ObjectPool
  ) {
    super(id, attributes, objectPool);

    this.id = this.attribute('id');
    this.feature = this.attribute('feature');
    this.isSynced = this.attribute('isSynced');
  }
}
