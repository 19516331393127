import { z } from 'zod';

//
// submittal
//
export type SubmittalStatus = 'open' | 'closed' | 'cancelled';
export type SubmittalType =
  | 'shop_drawings'
  | 'product_details'
  | 'samples'
  | 'documents';
export const submittalTypes = [
  'shop_drawings',
  'product_details',
  'samples',
  'documents',
] as const;
export const submittalTypeLabels = {
  shop_drawings: 'Shop Drawings',
  product_details: 'Product Details',
  samples: 'Samples',
  documents: 'Documents',
};
export const submittalSchema = z.object({
  id: z.string(),
  description: z.string().nullish(),
  number: z.number().positive(),
  status: z.enum(['open', 'closed', 'cancelled']),
  title: z.string(),
  type: z.enum(submittalTypes).nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  contractorUserId: z.string(),
  managerUserId: z.string(),
  submittalGroupId: z.string().nullish(),
  topicId: z.string(),
  currentStageId: z.string().nullish(),
});
export type Submittal = z.infer<typeof submittalSchema>;

//
// Approver
//
export const approverResponseTypes = [
  'no_exceptions_taken',
  'approved_as_noted',
  'revise_and_resubmit',
  'rejected',
] as const;
export const approverResponseTypeLabels = {
  no_exceptions_taken: 'No Exceptions Taken',
  approved_as_noted: 'Approved As Noted',
  revise_and_resubmit: 'Revise And Resubmit',
  rejected: 'Rejected',
};
export type ApproverResponseType =
  | 'no_exceptions_taken'
  | 'approved_as_noted'
  | 'revise_and_resubmit'
  | 'rejected';
export const responseTypesConsideredApproved = [
  'no_exceptions_taken',
  'approved_as_noted',
];
export const responseTypesConsideredRejected = [
  'revise_and_resubmit',
  'rejected',
];
export const submittalApproverStatuses = [
  'rejected',
  'approved',
  'pending',
] as const;
export type SubmittalApproverStatus = 'rejected' | 'approved' | 'pending';
export const submittalApproverSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittalItemId: z.string(),
  userId: z.string(),
  respondedAt: z.string().datetime().nullish(),
  responseType: z.enum(approverResponseTypes).nullish(),
  status: z.enum(submittalApproverStatuses).nullish(),
  comment: z.string().nullish(),
});
export type SubmittalApprover = z.infer<typeof submittalApproverSchema>;

//
// Document Page
//
export const submittalDocumentPageSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  pdfPageId: z.string(),
  submittalId: z.string(),
});
export type SubmittalDocumentPage = z.infer<typeof submittalDocumentPageSchema>;

//
// Document
//
export const submittalDocumentSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  pdfId: z.string().nullish(),
  submittalId: z.string().nullish(),
  fileUrl: z.string().nullish(),
});
export type SubmittalDocument = z.infer<typeof submittalDocumentSchema>;

//
// Approver Document
//
export const submittalApproverDocumentSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittalApproverId: z.string().nullish(),
  fileUrl: z.string().nullish(),
});
export type SubmittalApproverDocument = z.infer<
  typeof submittalApproverDocumentSchema
>;

//
// Group
//
export const submittalGroupSchema = z.object({
  id: z.string(),
  title: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export type SubmittalGroup = z.infer<typeof submittalGroupSchema>;

//
// Item
//
const submittalItemStatuses = ['rejected', 'approved', 'pending'] as const;
export const submittalItemSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittalStageId: z.string(),
  status: z.enum(submittalItemStatuses),
});
export type SubmittalItem = z.infer<typeof submittalItemSchema>;

//
// Stage
//
export const submittalStageStatuses = [
  'approved',
  'rejected',
  'in_progress',
  'blocked',
] as const;
export type SubmittalStageStatus =
  | 'approved'
  | 'rejected'
  | 'in_progress'
  | 'blocked';
export type ApprovalCondition = 'all' | 'one';
export const approvalConditions = ['all', 'one'] as const;
export const approvalConditionLabels = {
  all: 'All responses required to progress',
  one: 'At least one response required to progress',
};
export const submittalStageSchema = z.object({
  id: z.string(),
  dueOn: z.string().date(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittalId: z.string(),
  approvalCondition: z.enum(approvalConditions),
  status: z.enum(submittalStageStatuses),
});
export type SubmittalStage = z.infer<typeof submittalStageSchema>;

//
// Subscriber
//
export const submittalSubscriberSchema = z.object({
  id: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittalId: z.string(),
  userId: z.string(),
});
export type SubmittalSubscriber = z.infer<typeof submittalSubscriberSchema>;
