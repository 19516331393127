import { DrawingArea } from '@/models/drawing-area';
import { WriteTransaction } from 'replicache';

export default async function updateDrawingAreas(
  tx: WriteTransaction,
  args: {
    drawingAreas: {
      id: string;
      name: string;
      updatedAt: string;
    }[];
  }
) {
  for (const drawingArea of args.drawingAreas) {
    const existingDrawingArea = await DrawingArea.mustGet(tx, drawingArea.id);
    await DrawingArea.set(tx, { ...existingDrawingArea, ...drawingArea });
  }
}
