import { WriteTransaction } from 'replicache';

import { type DrawingSet } from './schema';
import drawingSetOps from './ops';

type CreateDrawingSetArgs = DrawingSet & {
  signedIds: string[];
};

export const createDrawingSet = async (
  tx: WriteTransaction,
  args: CreateDrawingSetArgs
) => {
  const { signedIds, ...rest } = args;
  await drawingSetOps.set(tx, rest);
};
