import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { mentionSchema } from './schema';

export const mentionOps = generate('mentions', mentionSchema.parse);

type MarkMentionAsViewedArgs = { mentionId: string };
export async function markMentionAsViewed(
  tx: WriteTransaction,
  { mentionId }: MarkMentionAsViewedArgs
) {
  await mentionOps.delete(tx, mentionId);
}
