import { z } from 'zod';

export type Comment = z.infer<typeof commentSchema>;

export type MediaAttachment = z.infer<typeof mediaAttachmentSchema>;
export type ScreenRecordingInfo = z.infer<typeof screenRecordingSchema>;

export const mediaAttachmentSchema = z.object({
  id: z.string().nullish(),
  name: z.string(),
  originalUrl: z.string(),
  largeUrl: z.string().nullish(),
  thumbnailUrl: z.string().nullish(),
});

export const screenRecordingSchema = z.object({
  id: z.string(),
  url: z.string().nullish(),
});

export const commentSchema = z.object({
  id: z.string(),
  content: z.string(),
  commentThreadId: z.string(),
  commenterId: z.string(),
  mediaAttachments: z.array(mediaAttachmentSchema).nullish(),
  screenRecordingInfo: screenRecordingSchema.nullish(),
  editedAt: z.string().datetime().nullish(),
  deletedAt: z.string().datetime().nullish(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
