import { z } from 'zod';

export type CompanyMembership = z.infer<typeof companyMembershipSchema>;

export const companyMembershipSchema = z.object({
  id: z.string(),
  userId: z.string(),
  companyId: z.string(),
  organizationId: z.string(),
  role: z.enum(['sub_contractor', 'tombstone']),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  lastInvitedAt: z.string().datetime().nullish(),
});
