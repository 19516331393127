import { Topic } from '@/models/issue/model';
import { Submittal, SubmittalType } from '@/models/submittal';
import { SubmittalApprover } from '@/models/submittal-approver';
import { SubmittalDocument } from '@/models/submittal-document';
import { SubmittalGroup } from '@/models/submittal-group';
import {
  SubmittalStage,
  SubmittalStageApprovalCondition,
} from '@/models/submittal-stage';
import { SubmittalSubscriber } from '@/models/submittal-subscriber';
import { WriteTransaction } from 'replicache';

export async function createSubmittal(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    description: string;
    type: SubmittalType | null;
    submittalGroupId: string | null | undefined;
    submittalGroupTitleIfNew: string | null | undefined;
    managerUserId: string;
    contractorUserId?: string | null | undefined;
    documentIds: string[];
    now: string;
    topicId: string;
    subscriberUserIds: {
      id: string;
      userId: string;
    }[];
    stages: {
      id: string;
      defaultItemItem: string;
      dueOn: string;
      approverUserIds: {
        id: string;
        userId: string;
      }[];
      approvalCondition: SubmittalStageApprovalCondition;
    }[];
  }
) {
  await Topic.set(tx, {
    id: args.topicId,
    title: args.title,
    status: 'editing',
    type: 'submittal',
    companyAccessible: true,
    organizationAccessible: true,
    createdAt: args.now,
    updatedAt: args.now,
  });

  if (args.submittalGroupId) {
    const found = (await SubmittalGroup.list(tx)).find(
      (x) => x.id === args.submittalGroupId
    );

    if (!found && args.submittalGroupTitleIfNew) {
      await SubmittalGroup.set(tx, {
        id: args.submittalGroupId,
        title: args.submittalGroupTitleIfNew,
        createdAt: args.now,
        updatedAt: args.now,
      });
    }
  }

  await Submittal.set(tx, {
    id: args.id,
    description: args.description,
    number: Math.max(...(await Submittal.list(tx)).map((x) => x.number), 0) + 1,
    status: 'open',
    title: args.title,
    type: args.type,
    createdAt: args.now,
    updatedAt: args.now,
    contractorUserId: args.contractorUserId,
    managerUserId: args.managerUserId,
    submittalGroupId: args.submittalGroupId,
    topicId: args.topicId,
  });

  for (const s of args.subscriberUserIds) {
    await SubmittalSubscriber.set(tx, {
      id: s.id,
      userId: s.userId,
      submittalId: args.id,
      createdAt: args.now,
      updatedAt: args.now,
    });
  }

  const docs = (await SubmittalDocument.list(tx)).filter((x) =>
    args.documentIds.includes(x.id)
  );

  for (const d of docs) {
    await SubmittalDocument.set(tx, { ...d, submittalId: args.id });
  }

  for (const s of args.stages) {
    await SubmittalStage.set(tx, {
      id: s.id,
      dueOn: s.dueOn,
      createdAt: args.now,
      updatedAt: args.now,
      submittalId: args.id,
      approvalCondition: s.approvalCondition,
    });

    for (const a of s.approverUserIds) {
      await SubmittalApprover.set(tx, {
        id: a.id,
        userId: a.userId,
        submittalStageId: s.id,
        createdAt: args.now,
        updatedAt: args.now,
      });
    }
  }
}
