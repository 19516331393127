import { Project } from '@/models/project';
import { createContext, useContext } from 'react';

export const ProjectContext = createContext<Project | null>(null);

export function useProject() {
  const project = useContext(ProjectContext);

  if (!project) {
    throw new Error('useProject must be used inside the route');
  }

  return project;
}
