import { generate } from '@rocicorp/rails';
import { parseISO } from 'date-fns';
import { ReadTransaction, WriteTransaction } from 'replicache';
import {
  DailyLog,
  DailyLogEquipmentEntry,
  dailyLogEquipmentEntrySchema,
  DailyLogPersonnelEntry,
  dailyLogPersonnelEntrySchema,
  dailyLogSchema,
} from './schema';

export const dailyLogOps = generate('dailyLogs', dailyLogSchema.parse);

export const dailyLogPersonnelEntryOps = generate(
  'dailyLogPersonnelEntries',
  dailyLogPersonnelEntrySchema.parse
);

export const dailyLogEquipmentEntryOps = generate(
  'dailyLogEquipmentEntries',
  dailyLogEquipmentEntrySchema.parse
);

export async function getDailyLogsBefore(tx: ReadTransaction, date: Date) {
  const dailyLogs = await dailyLogOps.list(tx);
  return dailyLogs
    .filter((entry) => parseISO(entry.date) < date)
    .sort((a, b) => parseISO(a.date).getTime() - parseISO(b.date).getTime());
}

export async function getDailyLogPersonnelEntries(
  tx: ReadTransaction,
  dailyLogId: string
) {
  const entries = await dailyLogPersonnelEntryOps.list(tx);
  return entries.filter((entry) => entry.dailyLogId === dailyLogId);
}

export async function getDailyLogEquipmentEntries(
  tx: ReadTransaction,
  dailyLogId: string
) {
  const entries = await dailyLogEquipmentEntryOps.list(tx);
  return entries.filter((entry) => entry.dailyLogId === dailyLogId);
}

export async function getPreviousDailyLogPersonnelEntries(
  tx: ReadTransaction,
  dailyLog: DailyLog
) {
  const previousLogs = await getDailyLogsBefore(tx, parseISO(dailyLog.date));

  const allPreviousEntries = await Promise.all(
    previousLogs.reverse().map((log) => getDailyLogPersonnelEntries(tx, log.id))
  );

  return allPreviousEntries.find((entries) => entries.length > 0) || [];
}

export async function getPreviousDailyLogEquipmentEntries(
  tx: ReadTransaction,
  dailyLog: DailyLog
) {
  const previousLogs = await getDailyLogsBefore(tx, parseISO(dailyLog.date));

  const allPreviousEntries = await Promise.all(
    previousLogs.reverse().map((log) => getDailyLogEquipmentEntries(tx, log.id))
  );

  return allPreviousEntries.find((entries) => entries.length > 0) || [];
}

export async function putDailyLog(tx: WriteTransaction, dailyLog: DailyLog) {
  await dailyLogOps.set(tx, { ...dailyLog });
}

export async function putDailyLogPersonnelEntry(
  tx: WriteTransaction,
  personnelEntry: DailyLogPersonnelEntry
) {
  await dailyLogPersonnelEntryOps.set(tx, personnelEntry);
}

export async function deleteDailyLogPersonnelEntry(
  tx: WriteTransaction,
  { id }: { id: string }
) {
  await dailyLogPersonnelEntryOps.delete(tx, id);
}

export async function putDailyLogEquipmentEntry(
  tx: WriteTransaction,
  equipmentEntry: DailyLogEquipmentEntry
) {
  await dailyLogEquipmentEntryOps.set(tx, equipmentEntry);
}

export async function deleteDailyLogEquipmentEntry(
  tx: WriteTransaction,
  { id }: { id: string }
) {
  await dailyLogEquipmentEntryOps.delete(tx, id);
}
