import { DrawingSet, DrawingSetPage } from '@/lib/drawings';
import { compact, groupBy, groupJoin } from '@/lib/query';
import { DrawingDiscipline } from '@/models/drawing-discipline/schema';
import { parseISO } from 'date-fns';
import { naturalSort } from './utils';

export type Options = {
  drawingSet?: DrawingSet | null;
  showUnpublishedRevisions?: boolean | null;
};

export function latestRevisionBySheetNumber(
  drawingSets: Array<DrawingSet>,
  drawingSetPages: Array<DrawingSetPage>,
  options?: Options
) {
  return compact(
    revisionsBySheetNumber(drawingSets, drawingSetPages, options).map(
      (x) => x.revisions[0]
    )
  );
}

export function latestRevisionsByDiscipline(
  drawingSets: Array<DrawingSet>,
  drawingSetPages: Array<DrawingSetPage>,
  drawingDisciplines: Array<DrawingDiscipline>,
  options?: Options
) {
  const disciplines = [...drawingDisciplines, uncategorizedDiscipline()];

  return groupJoin(
    disciplines,
    latestRevisionBySheetNumber(drawingSets, drawingSetPages, options),
    (a, b) =>
      b.drawingDisciplineId === a.id ||
      (!b.drawingDisciplineId && a.id === 'uncategorized-id'),
    'sheets',
    {
      having: (group) => group.sheets.length > 0,
    }
  ).sort((a, b) => a.position - b.position);
}

export function revisionsBySheetNumber(
  drawingSets: Array<DrawingSet>,
  drawingSetPages: Array<DrawingSetPage>,
  options?: Options
) {
  const pages = drawingSetPages.filter(
    (x) =>
      (!options?.drawingSet || x.drawingSetId === options.drawingSet.id) &&
      ((x.state === 'awaiting_publish' && options?.showUnpublishedRevisions) ||
        x.state === 'published')
  );

  const pagesWithDrawingSet = groupJoin(
    pages,
    drawingSets,
    (a, b) => a.drawingSetId === b.id,
    'sets'
  );

  return groupBy(
    pagesWithDrawingSet,
    (x) => x.sheetNumber ?? '',
    'sheetNumber',
    'revisions',
    {
      having: (group) => group.length > 0,
      orderBy: (a, b) => {
        const setA = a.sets[0];
        const setB = b.sets[0];
        return orderByRevision(setA!, a, setB!, b);
      },
    }
  ).sort((a, b) => naturalSort(a.sheetNumber, b.sheetNumber));
}

export function orderByRevision(
  drawingSetA: DrawingSet,
  drawingSetPageA: DrawingSetPage,
  drawingSetB: DrawingSet,
  drawingSetPageB: DrawingSetPage
): number {
  const receivedOnCmp =
    drawingSetA.receivedOn &&
    drawingSetB.receivedOn &&
    parseISO(drawingSetB.receivedOn).getTime() -
      parseISO(drawingSetA.receivedOn).getTime();
  if (receivedOnCmp && receivedOnCmp !== 0) {
    return receivedOnCmp;
  }
  return (
    parseISO(drawingSetPageB.createdAt).getTime() -
    parseISO(drawingSetPageA.createdAt).getTime()
  );
}

function uncategorizedDiscipline(): DrawingDiscipline {
  return {
    id: 'uncategorized-id',
    projectId: 'project-id',
    organizationId: 'organization-id',
    name: 'Uncategorized',
    position: Number.MAX_SAFE_INTEGER,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
}
