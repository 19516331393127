import { Project } from '@/models/project';
import { WriteTransaction } from 'replicache';

export default async function editProjectSettings(
  tx: WriteTransaction,
  args: {
    id: string;
    name: string;
    number?: string | null | undefined;
    coverPhotoUrl?: string | null;
    coverPhotoSignedId?: string | null;
    removeCoverPhoto?: boolean;
    settingsUpdatedAt: string;
  }
) {
  const project = await Project.mustGet(tx, args.id);
  await Project.set(tx, {
    ...project,
    name: args.name,
    number: args.number,
    coverPhotoUrl: args.coverPhotoUrl,
    settingsUpdatedAt: args.settingsUpdatedAt,
  });
}
