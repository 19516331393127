import { z } from 'zod';
import { commentThreadSchema } from '../comment-thread/schema';
import { commentSchema } from '../comment/schema';
import { discussionSchema } from '../discussion/schema';
import { drawingSetPageSchema } from '../drawing-set-page/schema';
import { drawingSetSchema } from '../drawing-set/schema';
import { issueSchema } from '../issue/schema';
import { markupSessionSchema } from '../markup-session/schema';
import { markupShapeSchema } from '../markup-shape/schema';
import { PunchListItem } from '../punch-list-item';
import { userSchema } from '../user/schema';

//
// BASE
//

const baseFields = {
  id: z.string(),
  userId: z.string(),
  createdAt: z.string().datetime(),
};

export type Generic = z.infer<typeof genericSchema>;
const genericSchema = z.object({
  ...baseFields,
  type: z.literal('generic'),
  message: z.string(),
  redirectId: z.string(),
});

export type AddComment = z.infer<typeof addCommentSchema>;
const addCommentSchema = z.object({
  ...baseFields,
  type: z.literal('add_comment'),
  commentId: z.string(),
  commentThreadId: z.string(),
});

export type AddThread = z.infer<typeof addThreadSchema>;
const addThreadSchema = z.object({
  ...baseFields,
  type: z.literal('add_thread'),
  commentId: z.string(),
  commentThreadId: z.string(),
});

export type ResolveThread = z.infer<typeof resolveThreadSchema>;
const resolveThreadSchema = z.object({
  ...baseFields,
  type: z.literal('resolve_thread'),
  commentThreadId: z.string(),
});

export type ReopenThread = z.infer<typeof reopenThreadSchema>;
const reopenThreadSchema = z.object({
  ...baseFields,
  type: z.literal('reopen_thread'),
  commentThreadId: z.string(),
});

export type AddIssue = z.infer<typeof addIssueSchema>;
const addIssueSchema = z.object({
  ...baseFields,
  type: z.literal('add_issue'),
  issueId: z.string(),
});

export type AddUserToIssue = z.infer<typeof addUserToIssueSchema>;
const addUserToIssueSchema = z.object({
  ...baseFields,
  type: z.literal('add_user_to_issue'),
  issueId: z.string(),
  toUserId: z.string(),
});

export type RemoveUserFromIssue = z.infer<typeof removeUserFromIssueSchema>;
const removeUserFromIssueSchema = z.object({
  ...baseFields,
  type: z.literal('remove_user_from_issue'),
  issueId: z.string(),
  toUserId: z.string(),
});

export type MarkupSessionLog = z.infer<typeof markupSessionLogSchema>;
const markupSessionLogSchema = z.object({
  ...baseFields,
  type: z.literal('markup_session'),
  issueId: z.string(),
  markupSessionId: z.string(),
});

export type PunchListItemCreate = z.infer<typeof punchListItemCreateSchema>;
const punchListItemCreateSchema = z.object({
  ...baseFields,
  type: z.literal('punch_list_item_create'),
  punchListItemId: z.string(),
  issueId: z.string(),
});

export type PunchListItemOpen = z.infer<typeof punchListItemOpenSchema>;
const punchListItemOpenSchema = z.object({
  ...baseFields,
  type: z.literal('punch_list_item_open'),
  punchListItemId: z.string(),
  issueId: z.string(),
});

export type PunchListItemInReview = z.infer<typeof punchListItemInReviewSchema>;
const punchListItemInReviewSchema = z.object({
  ...baseFields,
  type: z.literal('punch_list_item_in_review'),
  punchListItemId: z.string(),
  issueId: z.string(),
});

export type PunchListItemResolved = z.infer<typeof punchListItemResolvedSchema>;
const punchListItemResolvedSchema = z.object({
  ...baseFields,
  type: z.literal('punch_list_item_resolved'),
  punchListItemId: z.string(),
  issueId: z.string(),
});

export type ActivityLog = z.infer<typeof activityLogSchema>;
export const activityLogSchema = z.discriminatedUnion('type', [
  genericSchema,
  addCommentSchema,
  addThreadSchema,
  resolveThreadSchema,
  reopenThreadSchema,
  addIssueSchema,
  addUserToIssueSchema,
  removeUserFromIssueSchema,
  markupSessionLogSchema,
  punchListItemCreateSchema,
  punchListItemOpenSchema,
  punchListItemInReviewSchema,
  punchListItemResolvedSchema,
]);

//
// LOADED
//

const baseLoadedFields = {
  id: z.string(),
  user: userSchema,
  createdAt: z.string().datetime(),
};

export type LoadedGeneric = z.infer<typeof loadedGenericSchema>;
const loadedGenericSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('generic'),
  message: z.string(),
  redirectId: z.string(),
  issue: issueSchema.nullish(),
});

export type LoadedAddComment = z.infer<typeof loadedAddCommentSchema>;
const loadedAddCommentSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('add_comment'),
  issue: issueSchema.nullish(),
  comment: commentSchema,
  commentThread: commentThreadSchema,
  drawingSetPage: drawingSetPageSchema.nullish(),
  drawingSet: drawingSetSchema.nullish(),
  parentComment: commentSchema,
  parentCommenter: userSchema,
  markupShape: markupShapeSchema.nullish(),
});

export type LoadedAddThread = z.infer<typeof loadedAddThreadSchema>;
const loadedAddThreadSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('add_thread'),
  comment: commentSchema,
  commentThread: commentThreadSchema,

  discussion: discussionSchema,

  issue: issueSchema.nullish(),
  drawingSetPage: drawingSetPageSchema.nullish(),
  drawingSet: drawingSetSchema.nullish(),
  markupShape: markupShapeSchema.nullish(),

  punchListItem: PunchListItem.schema.nullish(),
});

export type LoadedResolveThread = z.infer<typeof loadedResolveThreadSchema>;
const loadedResolveThreadSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('resolve_thread'),
  commentThread: commentThreadSchema,

  issue: issueSchema.nullish(),
  drawingSetPage: drawingSetPageSchema.nullish(),
  drawingSet: drawingSetSchema.nullish(),
});

export type LoadedReopenThread = z.infer<typeof loadedReopenThreadSchema>;
const loadedReopenThreadSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('reopen_thread'),
  commentThread: commentThreadSchema,

  issue: issueSchema.nullish(),
  drawingSetPage: drawingSetPageSchema.nullish(),
  drawingSet: drawingSetSchema.nullish(),
});

export type LoadedAddIssue = z.infer<typeof loadedAddIssueSchema>;
const loadedAddIssueSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('add_issue'),
  issue: issueSchema,
});

export type LoadedAddUserToIssue = z.infer<typeof loadedAddUserToIssueSchema>;
const loadedAddUserToIssueSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('add_user_to_issue'),
  issue: issueSchema,
  toUser: userSchema,
});

export type LoadedRemoveUserFromIssue = z.infer<
  typeof loadedRemoveUserFromIssueSchema
>;
const loadedRemoveUserFromIssueSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('remove_user_from_issue'),
  issue: issueSchema,
  toUser: userSchema,
});

export type LoadedMarkupSessionLog = z.infer<
  typeof loadedMarkupSessionLogSchema
>;
const loadedMarkupSessionLogSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('markup_session'),
  issue: issueSchema,
  markupSession: markupSessionSchema,
});

export type LoadedPunchListItemCreate = z.infer<
  typeof loadedPunchListItemCreateSchema
>;
const loadedPunchListItemCreateSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('punch_list_item_create'),
  punchListItem: PunchListItem.schema,
  issue: issueSchema,
});

export type LoadedPunchListItemOpen = z.infer<
  typeof loadedPunchListItemOpenSchema
>;
const loadedPunchListItemOpenSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('punch_list_item_open'),
  punchListItem: PunchListItem.schema,
  issue: issueSchema,
});

export type LoadedPunchListItemInReview = z.infer<
  typeof loadedPunchListItemInReviewSchema
>;
const loadedPunchListItemInReviewSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('punch_list_item_in_review'),
  punchListItem: PunchListItem.schema,
  issue: issueSchema,
});

export type LoadedPunchListItemResolved = z.infer<
  typeof loadedPunchListItemResolvedSchema
>;
const loadedPunchListItemResolvedSchema = z.object({
  ...baseLoadedFields,
  type: z.literal('punch_list_item_resolved'),
  punchListItem: PunchListItem.schema,
  issue: issueSchema,
});

export type LoadedActivityLog = z.infer<typeof loadedActivityLogSchema>;
export const loadedActivityLogSchema = z.discriminatedUnion('type', [
  loadedGenericSchema,
  loadedAddCommentSchema,
  loadedAddThreadSchema,
  loadedResolveThreadSchema,
  loadedReopenThreadSchema,
  loadedAddIssueSchema,
  loadedAddUserToIssueSchema,
  loadedRemoveUserFromIssueSchema,
  loadedMarkupSessionLogSchema,
  loadedPunchListItemCreateSchema,
  loadedPunchListItemOpenSchema,
  loadedPunchListItemInReviewSchema,
  loadedPunchListItemResolvedSchema,
]);
