import { Organization } from '@/models/organization/model';
import { createContext, useContext } from 'react';

export const OrganizationContext = createContext<Organization | null>(null);

export function useOrganization() {
  const organization = useContext(OrganizationContext);
  if (!organization) {
    throw new Error('useOrganization must be used with context');
  }

  return organization;
}
