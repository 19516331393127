import { generate } from '@rocicorp/rails';
import { WriteTransaction } from 'replicache';
import { commentSchema, MediaAttachment, ScreenRecordingInfo } from './schema';

export const commentOps = generate('comments', commentSchema.parse);

type EditCommentArgs = {
  id: string;
  content: string;
  editedAt: string;
  updatedAt: string;
  mediaAttachments: Array<MediaAttachment>;
  screenRecordingInfo: ScreenRecordingInfo | null;

  // These are not used by the local mutation but is used by the server.
  // While we're waiting for the poke and refresh, it appears as if there's no media for the comment.
  mediaAttachmentIds: Array<string>;
  signedMediaAttachmentFileIds: Array<string>;
  screenRecordingFileId: string | null;
  signedScreenRecordingId: string | null;
};

export async function editComment(
  tx: WriteTransaction,
  {
    id,
    content,
    editedAt,
    updatedAt,
    mediaAttachments,
    screenRecordingInfo,
  }: EditCommentArgs
) {
  await commentOps.update(tx, {
    id,
    content,
    editedAt,
    updatedAt,
    mediaAttachments,
    screenRecordingInfo,
  });
}

type DeleteCommentArgs = {
  id: string;
  deletedAt: string;
  updatedAt: string;
};

export async function deleteComment(
  tx: WriteTransaction,
  { id, deletedAt, updatedAt }: DeleteCommentArgs
) {
  await commentOps.update(tx, { id, deletedAt, updatedAt });
}
