import invariant from '@/lib/invariant';
import { generate } from '@rocicorp/rails';
import { sortBy } from 'lodash';
import { ReadTransaction, WriteTransaction } from 'replicache';
import { activityLogOps } from '../activity-log/ops';
import { issueMembershipOps } from '../issue-membership/ops';
import { markupShapeOps } from '../markup-shape/ops';
import { issueSchema } from './schema';

export const issueOps = generate('issues', issueSchema.parse);

export async function getPunchListIssue(tx: ReadTransaction) {
  const issues = await issueOps.list(tx);
  return sortBy(issues, 'createdAt').find((i) => i.type === 'punch_list');
}

export async function getIssuesForProject(tx: ReadTransaction) {
  const issues = await issueOps.list(tx);
  return sortBy(issues, 'updatedAt').reverse();
}

export async function createIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    status: 'new' | 'draft' | 'editing' | 'converted';
    companyAccessible: boolean;
    organizationAccessible: boolean;
    createdAt: string;
    updatedAt: string;
    membershipId: string;
    userId: string;
  }
) {
  await issueOps.set(tx, { ...args, type: 'topic' });
  await issueMembershipOps.set(tx, {
    id: args.membershipId,
    issueId: args.id,
    userId: args.userId,
    createdAt: args.createdAt,
    updatedAt: args.updatedAt,
  });
}

export async function moveIssueFromNewToDraft(
  tx: WriteTransaction,
  args: { id: string; title: string }
) {
  const issue = await issueOps.mustGet(tx, args.id);
  if (issue.status === 'new') {
    await issueOps.set(tx, { ...issue, status: 'draft', title: args.title });
  }
}

export async function resetDraftIssue(
  tx: WriteTransaction,
  args: { id: string; title: string }
) {
  const issue = await issueOps.mustGet(tx, args.id);
  const shapes = (await markupShapeOps.list(tx)).filter(
    (s) => s.issueId === issue.id
  );

  if (issue.status === 'draft') {
    await issueOps.set(tx, { ...issue, status: 'new', title: args.title });
    for (const s of shapes) {
      await markupShapeOps.delete(tx, s.id);
    }
  }
}

export async function updateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    status: 'new' | 'draft' | 'editing' | 'converted';
    companyAccessible: boolean;
    organizationAccessible: boolean;
    directAccessUserIds: string[];
    updatedAt: string;
  }
) {
  // directAccessUserIds accessible after pull
  const issue = await issueOps.mustGet(tx, args.id);
  await issueOps.set(tx, {
    ...issue,
    title: args.title,
    status: args.status,
    updatedAt: args.updatedAt,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
  });
}

export async function archiveIssue(
  tx: WriteTransaction,
  args: { id: string; updatedAt: string }
) {
  await issueOps.update(tx, {
    id: args.id,
    status: 'archived',
    updatedAt: args.updatedAt,
  });
}

export async function unarchiveIssue(
  tx: WriteTransaction,
  args: { id: string; updatedAt: string }
) {
  await issueOps.update(tx, {
    id: args.id,
    status: 'editing',
    updatedAt: args.updatedAt,
  });
}

export async function mergeTopics(
  tx: WriteTransaction,
  args: { fromTopicId: string; toTopicId: string; updatedAt: string }
) {
  const fromTopic = await issueOps.mustGet(tx, args.fromTopicId);
  const toTopic = await issueOps.mustGet(tx, args.toTopicId);

  const shapes = (await markupShapeOps.list(tx)).filter(
    (s) => s.issueId === fromTopic.id
  );

  for (const shape of shapes) {
    await markupShapeOps.set(tx, {
      ...shape,
      issueId: toTopic.id,
      updatedAt: args.updatedAt,
    });
  }

  const activityLogs = (await activityLogOps.list(tx)).filter(
    (a) => 'issueId' in a && a.issueId === fromTopic.id
  );

  for (const activityLog of activityLogs) {
    invariant('issueId' in activityLog, 'activity log must have issueId');

    await activityLogOps.set(tx, {
      ...activityLog,
      issueId: toTopic.id,
    });
  }

  await issueOps.set(tx, {
    ...fromTopic,
    status: 'new',
    updatedAt: args.updatedAt,
  });
}

export async function newEditorCreateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    createdAt: string;
    memberships: { id: string; userId: string }[];
  }
) {
  await issueOps.set(tx, {
    id: args.id,
    title: args.title,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
    createdAt: args.createdAt,
    updatedAt: args.createdAt,
    status: 'editing',
    type: 'topic',
  });

  for (const membership of args.memberships) {
    await issueMembershipOps.set(tx, {
      id: membership.id,
      issueId: args.id,
      userId: membership.userId,
      createdAt: args.createdAt,
      updatedAt: args.createdAt,
    });
  }
}

export async function newEditorUpdateIssue(
  tx: WriteTransaction,
  args: {
    id: string;
    title: string;
    companyAccessible: boolean;
    organizationAccessible: boolean;
    updatedAt: string;
    memberships: { id: string; userId: string }[];
  }
) {
  const issue = await issueOps.mustGet(tx, args.id);

  await issueOps.set(tx, {
    id: args.id,
    title: args.title,
    companyAccessible: args.companyAccessible,
    organizationAccessible: args.organizationAccessible,
    createdAt: issue.createdAt,
    updatedAt: args.updatedAt,
    status: 'editing',
    type: 'topic',
  });

  const existingMemberships = (await issueMembershipOps.list(tx)).filter(
    (m) => m.issueId === args.id
  );

  for (const membership of args.memberships) {
    if (existingMemberships.some((m) => m.userId === membership.userId)) {
      continue;
    }

    await issueMembershipOps.set(tx, {
      id: membership.id,
      issueId: args.id,
      userId: membership.userId,
      createdAt: args.updatedAt,
      updatedAt: args.updatedAt,
    });
  }

  for (const membership of existingMemberships) {
    if (args.memberships.some((m) => m.userId === membership.userId)) {
      continue;
    }

    await issueMembershipOps.delete(tx, membership.id);
  }
}
